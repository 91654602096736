import PropTypes from 'prop-types';

import { stopMakeup } from '../orbo.config';
import { closeTryNow } from '../store/OrboConfig/OrboConfig.action';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        isOrboOpen: state.OrboReducer.isOpen
    };
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        closeOrbo: () => dispatch(closeTryNow())
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    isOrboOpen: PropTypes.bool.isRequired,
    closeOrbo: PropTypes.func.isRequired
});

export class ProductActionsContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        closeOrbo: this.closeOrbo.bind(instance)
    });

    closeOrbo() {
        const { closeOrbo } = this.props;
        closeOrbo();
        stopMakeup();
    }
}

const { containerFunctions } = new ProductActionsContainer();

const containerProps = (args, callback, instance) => {
    const { isOrboOpen } = instance.props;
    return {
        ...callback(args),
        isOrboOpen
    };
};

export default {
    'Scandi/Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandi/Component/ProductActions/Container': {
        'member-function': {
            containerProps
        },
        'member-property': {
            containerFunctions
        },
        'static-member': {
            propTypes
        }
    },
    'Scandi/Component/ProductActions/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
