/* eslint-disable max-len */
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    FETCH_USER_PROFILE,
    triggerEvent,
    UTM_DATA
} from '../../Gtm.config';

const onCustomerSaveFetchMutationThen = (args, callback) => {
    const [{ updateCustomer: { customer } }] = args;
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    const clevertapData = {
        Site: {
            Name: `${customer.firstname} ${customer.lastname}`,
            'UTM Source': utmData?.utm_source,
            'UTM Medium': utmData?.utm_medium,
            'UTM Campaign': utmData?.utm_campaign
        }
    };

    triggerEvent({
        event: FETCH_USER_PROFILE,
        ecommerce: null,
        clevertapData
    });

    callback(...args);
};

export default {
    'Scandi/Component/MyAccountCustomerPopup/Container/MyAccountCustomerPopupContainer/onCustomerSave/fetchMutation/then': {
        function: onCustomerSaveFetchMutationThen
    }
};
