/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */

export const orboVideoHeight = 540;
export const orboVideoWidth = 480;
export const orboWrapper = 'orboWrapper';
export const orboSdkID = 'orboScript';
export const orboId = '62b2b9ffcc41268e88ee3d0c';
export const orboToken = '62b2ec6463bae26325ae6159';
export const orboSdk = 'https://orbostorage1.blob.core.windows.net/webmakeupsdk/assets/supertouchMakeupSDK-1.0.1.js';

// eslint-disable-next-line import/no-mutable-exports, fp/no-let
export let isStarted = false; export let video = null;

/** @namespace Orbo/Orbo/Config/isMobile */
export const isMobile = () => window.innerWidth <= 810;

/** @namespace Orbo/Orbo/Config/injectOrboScript */
export const injectOrboScript = () => {
    const check = document.getElementById(orboSdkID);
    if (check) {
        return;
    }
    const script = document.createElement('script');
    script.setAttribute('src', orboSdk);
    script.setAttribute('id', orboSdkID);
    document.body.appendChild(script);
};

/** @namespace Orbo/Orbo/Config/startVideoStream */
export const startVideoStream = () => {
    let width = orboVideoWidth;
    let height = orboVideoHeight;
    if (isMobile()) {
        width = window.innerWidth;
        height = window.innerHeight;
    }

    return new Promise((resolve) => {
        navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: 'user',
                width,
                height
            }
        }).then(
            /** @namespace Orbo/Orbo/Config/startVideoStream/getUserMedia/then */
            (ms) => {
                video = ms;
                const inputVideo = document.getElementById('videoStream');
                inputVideo.srcObject = ms;
                inputVideo.play();
                const outputCanvas = document.getElementById('output');
                const onLoadedData = () => {
                    window['supertouch-makeup'].start(inputVideo, width, height, outputCanvas, orboId, orboToken)
                        .then(
                            /** @namespace Orbo/Orbo/Config/startVideoStream/getUserMedia/then/onLoadedData/start/then */
                            () => {
                                isStarted = true;
                            }
                        );
                    inputVideo.removeEventListener('loadeddata', onLoadedData);
                    resolve();
                };

                inputVideo.addEventListener('loadeddata', onLoadedData);
            }
        );
    });
};

/** @namespace Orbo/Orbo/Config/startMakeup */
export const startMakeup = (cb = () => {}) => {
    startVideoStream()
        .then(cb);
};

/** @namespace Orbo/Orbo/Config/applyMakeup */
export const applyMakeup = (skuId, pattern = 0) => {
    if (isStarted) {
        window['supertouch-makeup'].update(skuId, pattern);
    }
};

/** @namespace Orbo/Orbo/Config/clearCanvas */
export const clearCanvas = () => {
    const canvas = document.getElementById('output');
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    // eslint-disable-next-line no-multi-assign
    // canvas.height = canvas.width = 0;
};

/** @namespace Orbo/Orbo/Config/stopWebCam */
export const stopWebCam = () => {
    if (!video) {
        return;
    }
    video.getTracks().forEach((track) => track.stop());
};

/** @namespace Orbo/Orbo/Config/stopMakeup */
export const stopMakeup = () => {
    isStarted = false;
    stopWebCam();
    clearCanvas();
    window['supertouch-makeup'].stop();
};

/** @namespace Orbo/Orbo/Config/fileTobase64 */
export const fileTobase64 = (file) => new Promise((resolve, reject) => {
    try {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target.result);
        };
        reader.readAsDataURL(file);
    } catch (e) {
        reject(e);
    }
});

export const pattern = [
    'skin_health',
    'hydration',
    'smoothness',
    'firmness',
    'oxygen'
];
