/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-nested-ternary */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { Component } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Html from 'Component/Html';
import {
    DISPLAY_MODE_BOTH,
    DISPLAY_MODE_CMS_BLOCK
} from 'Route/CategoryPage/CategoryPage.config';
import { CategoryTreeType } from 'Type/Category.type';

import './PlpFooter.style';

/** @namespace Scandi/Component/PlpFooter/Component */
export class PlpFooterComponent extends Component {
    static propTypes = {
        isPLP: PropTypes.bool.isRequired,
        isHome: PropTypes.bool.isRequired,
        category: CategoryTreeType.isRequired,
        handleReadMore: PropTypes.func.isRequired,
        readMore: PropTypes.bool.isRequired
    };

    displayCmsBlock() {
        const { category: { display_mode } = {} } = this.props;
        return display_mode === DISPLAY_MODE_CMS_BLOCK
             || display_mode === DISPLAY_MODE_BOTH;
    }

    renderCmsBottomBlock() {
        const { category: { bottom_cms_block } } = this.props;

        if (!bottom_cms_block || !this.displayCmsBlock()) {
            return null;
        }

        const { content, disabled } = bottom_cms_block;

        if (disabled) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="CMS"
            >
                <Html content={ content } />
            </div>
        );
    }

    renderHomeBottomBlock() {
        return <CmsBlock identifier="homepage-bottom-block" />;
    }

    render() {
        const {
            isPLP,
            isHome,
            handleReadMore,
            readMore
        } = this.props;
        const { category: { bottom_cms_block } } = this.props;

        if (!bottom_cms_block || !this.displayCmsBlock()) {
            return null;
        }

        const { disabled } = bottom_cms_block;

        if (disabled) {
            return null;
        }

        if (!(isPLP || isHome)) {
            return null;
        }

        const readMoreText = readMore ? 'Read Less' : 'Read More';

        return (
            <div block="PlpFooter">
                <div className={ `PlpFooterContentWrapper ${readMore ? 'active' : null }` }>
                    { isPLP ? this.renderCmsBottomBlock() : isHome ? this.renderHomeBottomBlock() : null }
                </div>
                <div
                  key="ReadMoreButton"
                  block="ReadMore"
                  onClick={ handleReadMore }
                >
                    { readMoreText }
                </div>
            </div>
        );
    }
}

export default PlpFooterComponent;
