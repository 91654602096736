import { UrlParser as urlParser } from 'url-params-parser';

const render = (args, callback, instance) => {
    const query = urlParser(window.location.href).queryParams;

    if (query.utm_source) {
        localStorage.setItem('UTM_DATA', JSON.stringify(query));
    } else {
        localStorage.setItem('UTM_DATA', JSON.stringify({
            utm_source: 'Direct/Organic',
            utm_medium: 'Direct/Organic',
            utm_campaign: 'Direct/Organic'
        }));
    }

    return callback.apply(instance, args);
};

export default {
    'Scandi/Component/App/Component': {
        'member-function': {
            render
        }
    }
};
