import { ShiprocketReducer } from '../store/Shiprocket/Shiprocket.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    ShiprocketReducer
});

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
