import { Field } from 'Util/Query';

/** @namespace gaTracking/Query/GaTracking/Query */
export class GaTrackingQuery {
    getQuery(options) {
        return new Field('gaTracking')
            .addArgument('page_type', 'String!', options.page_type)
            .addArgument('event_data', 'String!', options.event_data)
            .addArgument('log_date', 'String!', options.log_date)
            .addFieldList([
                'success',
                'message'
            ]);
    }
}

export default new GaTrackingQuery();
