/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { ProductType } from 'Type/ProductList.type';
import { getBundleProductIds } from 'Util/helpers';
import { fetchMutation } from 'Util/Request';

import PreorderQuery from '../../query/Preorder.query';
import ProductAlertQuery from '../../query/ProductAlert.query';
import ProductAlertComponent from './ProductAlert.component';
/** @namespace Productalert/Component/ProductAlert/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    store_code: state.ConfigReducer.code
});

/** @namespace Productalert/Component/ProductAlert/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Productalert/Component/ProductAlert/Container */
export class ProductAlertContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        showNotification: PropTypes.func.isRequired,
        store_code: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        productOptionsData: PropTypes.object.isRequired
    };

    state = {
        email: '',
        popupOpen: false,
        name: '',
        phone_number: '',
        loading: false,
        errorMessage: '',
        successMessage: ''
    };

    containerFunctions = {
        onClickSubmit: this.onClickSubmit.bind(this),
        onChangeEmail: this.onChangeEmail.bind(this),
        openPopup: this.openPopup.bind(this),
        closePopup: this.closePopup.bind(this),
        onInputChange: this.onInputChange.bind(this),
        submitPreorder: this.submitPreorder.bind(this)
    };

    constainerProps() {
        const {
            email,
            popupOpen,
            name,
            phone_number,
            loading,
            errorMessage,
            successMessage
        } = this.state;

        const { product, showNotification } = this.props;

        return {
            email,
            product,
            showNotification,
            popupOpen,
            name,
            phone_number,
            loading,
            errorMessage,
            successMessage,
            preorder: this._getPreorder()
        };
    }

    onInputChange({ target: { name, value } }) {
        this.setState({
            [name]: value,
            errorMessage: '',
            successMessage: ''
        });
    }

    submitPreorder() {
        this.setState({
            errorMessage: '',
            successMessage: ''
        });
        const { showNotification, product: { sku } } = this.props;
        const {
            name,
            email,
            phone_number
        } = this.state;

        const isValid = this.validateData();

        if (!isValid) {
            return;
        }

        this.setState({
            loading: true,
            errorMessage: ''
        });

        fetchMutation(PreorderQuery.getQuery({
            product_sku: sku,
            name,
            email,
            phone_number
        }))
            .then(
                /** @namespace Productalert/Component/ProductAlert/Container/ProductAlertContainer/submitPreorder/fetchMutation/then */
                ({ preOrder }) => {
                    const { success, message } = preOrder;
                    if (success) {
                        showNotification('success', 'Thank you for contacting us!');
                        this.setState({
                            loading: false,
                            popupOpen: false,
                            email: '',
                            name: '',
                            phone_number: ''
                        });
                    } else {
                        showNotification('error', message);
                        this.setState({
                            loading: false
                        });
                    }
                },
                /** @namespace Productalert/Component/ProductAlert/Container/ProductAlertContainer/submitPreorder/fetchMutation/then/catch */
                () => {
                    showNotification('error', 'Something went wrong! please try again');
                }
            );
    }

    validateData() {
        const {
            name,
            phone_number
        } = this.state;
        const { product: { sku } } = this.props;

        if (!sku) {
            this.setState({
                errorMessage: 'Invalid product is selected'
            });

            return false;
        }

        if (name === '') {
            this.setState({
                errorMessage: 'Name is required'
            });

            return false;
        }

        if (phone_number === '') {
            this.setState({
                errorMessage: 'Mobile number is required'
            });

            return false;
        }

        return true;
    }

    _getPreorder() {
        const {
            product: {
                attributes: {
                    preorder: {
                        attribute_value = '0'
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (attribute_value === '1') {
            return true;
        }

        return false;
    }

    openPopup() {
        this.setState({ popupOpen: true });
    }

    closePopup() {
        this.setState({ popupOpen: false });
    }

    onClickSubmit() {
        const { email } = this.state;
        const {
            showNotification,
            product,
            store_code,
            product: {
                id: product_id = null,
                type_id = PRODUCT_TYPE.simple
            } = {},
            productOptionsData
        } = this.props;

        if (email === '') {
            showNotification('error', __('Email address is required'));
            return;
        }

        this.setState({
            loading: true,
            errorMessage: '',
            successMessage: ''
        });
        // eslint-disable-next-line fp/no-let
        let associate_id = [];
        if (type_id === PRODUCT_TYPE.bundle) {
            associate_id = getBundleProductIds(product, productOptionsData);
        }

        fetchMutation(ProductAlertQuery.getQuery({
            product_id,
            email,
            store_code,
            associate_id: associate_id.length ? associate_id.join('-') : ''
        }))
            .then(
                /** @namespace Productalert/Component/ProductAlert/Container/ProductAlertContainer/onClickSubmit/then/catch/fetchMutation/then */
                ({ ProductAlertNotifyMeInStock }) => {
                    const { message, success } = ProductAlertNotifyMeInStock;
                    this.setState({
                        loading: false,
                        popupOpen: false,
                        email: success ? '' : email,
                        errorMessage: success ? '' : message,
                        successMessage: success ? message : ''
                    });
                    showNotification(success ? 'success' : 'error', message);
                },
                /** @namespace Productalert/Component/ProductAlert/Container/ProductAlertContainer/onClickSubmit/then/catch/fetchMutation/then/catch */
                () => {
                    this.setState({
                        loading: false,
                        errorMessage: 'Something went wrong! please try again'
                    });
                    showNotification('error', 'Something went wrong! please try again');
                }
            )
            .catch(
                /** @namespace Productalert/Component/ProductAlert/Container/ProductAlertContainer/onClickSubmit/then/catch */
                () => {
                    this.setState({
                        loading: false,
                        errorMessage: 'Something went wrong! please try again'
                    });
                    showNotification('error', 'Something went wrong! please try again');
                }
            );
    }

    onChangeEmail(e) {
        this.setState({ email: e.target.value });
    }

    render() {
        return (
            <ProductAlertComponent
              { ...this.constainerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAlertContainer);
