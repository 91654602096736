/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import classNames from 'classnames';
import React from 'react';
import Swiper from 'swiper';

import 'swiper/swiper-bundle.css';

/** @namespace PageBuilder/Component/Tab/Component */
export class TabComponent extends React.PureComponent {
    __construct(props) {
        super.__construct(props);
        this.state = {
            activeIdx: 0
        };
    }

  onClickTabMenu = (e, idx) => {
      e.stopPropagation();
      e.preventDefault();
      this.setState({ activeIdx: idx });
  };

  async renderConcernCarousel() {
      const wrapperClass = '.ConcernSlidersRow';
      const sliderEl = `${wrapperClass} .ProductListPage`;

      while (!document.querySelector(sliderEl) || typeof document.querySelector(sliderEl) === 'undefined') {
          await new Promise((r) => setTimeout(r, 500));
      }

      setTimeout(() => {
          const wrapper = document.createElement('div');
          wrapper.classList.add('swiper-wrapper');
          const productlist = document.querySelectorAll(`${wrapperClass} .ProductCard`);
          for (let i = 0; i < productlist.length; ++i) {
              const slide = document.createElement('div');
              slide.classList.add('swiper-slide');
              slide.innerHTML = productlist[i].outerHTML;
              wrapper.innerHTML += slide.outerHTML;
          }
          document.querySelector(sliderEl).classList.remove('ProductListWidget-Page');
          document.querySelector(sliderEl).classList.add('active');
          document.querySelector(sliderEl).innerHTML = wrapper.outerHTML;
          document.querySelector(`${wrapperClass} .ProductList`).insertAdjacentHTML(
              'beforeend',
              `<div class="swiper-button-prev"></div>
             <div class="swiper-button-next"></div>`
          );
          // eslint-disable-next-line no-new
          new Swiper(sliderEl, {
              autoplay: true,
              loop: true,
              slidesPerView: 1,
              spaceBetween: 10,
              createElements: true,
              navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
              }
          });
          const { swiper } = document.querySelector(sliderEl);
          document.querySelector(`${wrapperClass} .swiper-button-prev`).addEventListener('click', () => {
              swiper.slidePrev();
          });

          document.querySelector(`${wrapperClass} .swiper-button-next`).addEventListener('click', () => {
              swiper.slideNext();
          });
      }, 500);
  }

  renderTabContent = (TabItem, activeIdx, TabContent) => {
      this.renderConcernCarousel();
      return (
        <TabContent.Ele>
            { TabItem.propsBag.map((props, idx) => activeIdx === idx
              && (
                <TabItem.Ele
                  key={ `tab-content-item-${idx}` }
                  { ...props }
                >
                  { TabItem.childEleBag[idx] }
                </TabItem.Ele>
              )) }
        </TabContent.Ele>
      );
  };

  renderTabMenu = (TabMenuHeader, activeIdx, TabMenuLink, TabMenuTitle, TabMenu) => (
<TabMenu.Ele
  className="tabs-navigation ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
>
    { TabMenuHeader.propsBag.map((props, idx) => (
      <TabMenuHeader.Ele
        { ...props }
        key={ `tab-menu-${idx}` }
        className={ classNames('tab-header ui-state-default ui-corner-top', { 'ui-tabs-active ui-state-active': activeIdx === idx }) }
        onClick={ (e) => this.onClickTabMenu(e, idx) }
      >
        <TabMenuLink.Ele { ...TabMenuLink.propsBag[idx] }>
          <TabMenuTitle.Ele>{ TabMenuTitle.childData[idx] }</TabMenuTitle.Ele>
        </TabMenuLink.Ele>
      </TabMenuHeader.Ele>
    )) }
</TabMenu.Ele>
  );

  render() {
      const {
          BaseTabs,
          TabMenu,
          TabMenuHeader,
          TabMenuLink,
          TabMenuTitle,
          TabContent,
          TabItem
      } = this.props.elements;
      const { activeIdx } = this.state;

      return (
<BaseTabs.Ele>
      { this.renderTabMenu(TabMenuHeader, activeIdx, TabMenuLink, TabMenuTitle, TabMenu) }
      { this.renderTabContent(TabItem, activeIdx, TabContent) }
</BaseTabs.Ele>
      );
  }
}

export default TabComponent;
