/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { createRef } from 'react';

import AddToCart from 'Component/AddToCart';
import CloseIcon from 'Component/CloseIcon';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductComponent } from 'Component/Product/Product.component';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { MixType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import { LayoutType } from 'Type/Layout.type';
import { LinkType } from 'Type/Router.type';

import { scrollToTop } from '../../util/Browser/Browser';

import './ProductCard.style';

/**
 * Product card
 * @class ProductCard
 * @namespace Scandi/Component/ProductCard/Component */
export class ProductCardComponent extends ProductComponent {
    static propTypes = {
        ...ProductComponent.propTypes,
        linkTo: LinkType,
        device: DeviceType.isRequired,
        thumbnail: PropTypes.string,
        isLoading: PropTypes.bool,
        children: PropTypes.element,
        layout: LayoutType,
        mix: MixType,
        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        hideWishlistButton: PropTypes.bool,
        isWishlistEnabled: PropTypes.bool.isRequired,
        hideCompareButton: PropTypes.bool,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        showSelectOptionsNotification: PropTypes.func.isRequired,
        // previewOpen: PropTypes.bool.isRequired,
        // openPreview: PropTypes.func.isRequired,
        // closePreview: PropTypes.func.isRequired,
        registerSharedElement: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...ProductComponent.defaultProps,
        thumbnail: '',
        linkTo: {},
        children: null,
        isLoading: false,
        mix: {},
        renderContent: false,
        hideWishlistButton: false,
        hideCompareButton: false,
        layout: GRID_LAYOUT
    };

    state = {
        showImg: false
    };

    contentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this)
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this)
        }
    };

    imageRef = createRef();

    className = 'ProductCard';

    handleLinkClick = this.handleLinkClick.bind(this);

    handleLinkClick() {
        const { registerSharedElement, isPlp } = this.props;

        if (!isPlp) {
            scrollToTop();
        }
        registerSharedElement(this.imageRef);
    }

    //#region PRICE
    renderEmptyProductPrice() {
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
              mods={ { isEmpty: true } }
            />
        );
    }

    renderPrice() {
        const {
            getActiveProduct,
            product: {
                type_id: baseType
            } = {}
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        // If product is not a variant.
        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        return super.renderPrice(notConfigured);
    }
    //#endregion

    changeShowImg(val) {
        this.setState({ showImg: val });
    }

    renderPicture(mix = {}) {
        const {
            product: {
                id,
                name,
                // thumbnail: { webpurl } = {},
                media_gallery_entries: mediaGallery = []
            },
            thumbnail
        } = this.props;

        const { showImg } = this.state;

        return (
            <div block="ProductCard" elem="FigureReview">
                <figure block="ProductCard" elem="Figure">
                    { mediaGallery.length > 1 && showImg ? (
                        <Image
                          imageRef={ this.imageRef }
                          src={ mediaGallery.slice(-1)[0].thumbnail.url }
                        //   webp_src={ mediaGallery.slice(-1)[0].thumbnail.webpurl }
                          alt={ name }
                          ratio="custom"
                          mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                          isPlaceholder={ !id }
                        />
                    ) : (
                        <Image
                          imageRef={ this.imageRef }
                          src={ thumbnail }
                        //   webp_src={ webpurl }
                          alt={ name }
                          ratio="custom"
                          mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                          isPlaceholder={ !id }
                        />
                    ) }
                </figure>
            </div>
        );
    }

    renderReviews() {
        const { layout } = this.props;

        return (
            <div
              block="ProductCard"
              elem="Reviews"
              mods={ { layout } }
            >
                { this.renderRatingSummary() }
            </div>
        );
    }

    renderProductCompareButton() {
        const {
            hideCompareButton
        } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return this.renderCompareButton();
    }

    renderProductCardWishlistButton() {
        const { hideWishlistButton, isWishlistEnabled } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return this.renderWishlistButton();
    }

    renderProductActions() {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
                { this.renderProductCompareButton() }
            </div>
        );
    }

    renderMainDetails() {
        const { product: { name } } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length="medium" />
            </p>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.handleLinkClick }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    requiresConfiguration() {
        const {
            parameters,
            product: {
                type_id: type,
                options = [],
                items = [],
                links_purchased_separately
            }
        } = this.props;

        const configureBundle = type === PRODUCT_TYPE.bundle;

        const allAttrs = super.getConfigurableAttributes();
        const plpConfigurableAttrs = this.getConfigurableAttributes();

        const isConfigurable = type === PRODUCT_TYPE.configurable;

        const configureConfig = isConfigurable && (
            (
                Object.keys(allAttrs).length
                !== Object.keys(plpConfigurableAttrs).length
            )
            || (
                Object.values(plpConfigurableAttrs).some(
                    (value) => value.attribute_values.length === 0
                )
            )
            || (Object.keys(allAttrs).length > 0 && Object.keys(parameters).length === 0)
        );

        const configureGrouped = type === PRODUCT_TYPE.grouped
            && items.every(({ qty }) => qty === 0);

        const configureCustomize = options.some(({ required = false }) => required);

        const configureDownloadableLinks = PRODUCT_TYPE.downloadable && links_purchased_separately === 1;

        return configureGrouped
            || configureBundle
            || configureConfig
            || configureCustomize
            || configureDownloadableLinks;
    }

    state = {
        previewOpen: false
    };

    openPreview() {
        this.setState({
            previewOpen: true
        });
    }

    closePreview() {
        this.setState({
            previewOpen: false
        });
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues
        } = this.props;

        return (
            <AddToCart
              mix={ { block: this.className, elem: 'AddToCart' } }
              addToCart={ addToCart }
              isDisabled={ !inStock }
              isIconEnabled
              layout={ layout }
              updateSelectedValues={ updateSelectedValues }
              quantity={ quantity }
              product={ getActiveProduct() }
            />
        );
    }

    renderAddToCart() {
        const {
            layout,
            product: { type_id = 'simple' } = {},
            inStock
            // linkTo
        } = this.props;
        const { previewOpen } = this.state;

        // eslint-disable-next-line no-unreachable
        if (inStock === true && !previewOpen && type_id === 'configurable') {
            return (
                <button
                  block="Button AddToCart ProductCard-AddToCart"
                  mods={ { layout } }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => this.openPreview() }
                >
                    { __('Preview Options') }
                </button>
            );
        }

        if (inStock === false) {
            return (
                <button
                  disabled
                  block="Button AddToCart ProductCard-AddToCart"
                  mods={ { layout } }
                >
                    { __('Sold Out') }
                </button>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    getConfigurableAttributes() {
        const filteredOptions = super.getConfigurableAttributes();

        return Object.fromEntries(Object.entries(filteredOptions).filter(([, option]) => {
            const { attribute_options: attributeOptions = {} } = option;

            return Object.values(attributeOptions).some(({ swatch_data: swatchData }) => swatchData);
        }));
    }

    renderPreviewOptions() {
        const { product: { variants, configurable_options } } = this.props;
        if (variants === undefined || variants.length < 1) {
            return null;
        }
        // eslint-disable-next-line fp/no-let
        let name = 'Shade';
        // eslint-disable-next-line array-callback-return
        Object.values(configurable_options).map((option) => {
            name = option.attribute_label;
        });

        return (
            <div block="ProductCard" elem="PreviewOptions">
                <div block="ProductCard" elem="PreviewOptionsHeader">
                    <p block="ProductCard" elem="PreviewOptionsHeading">{ `Select ${name} (${variants.length})` }</p>
                    <button
                      block="ProductCard"
                      elem="PreviewOptionsCloseButton"
                      type="button"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => this.closePreview() }
                    >
                        <CloseIcon />
                    </button>
                </div>
                { this.renderConfigurableOptions() }
            </div>
        );
    }

    renderVisibleOnHover() {
        const { previewOpen } = this.state;
        return (
            <>
                { previewOpen ? this.renderPreviewOptions() : null }
                <div block="ProductCard" elem="Footer">
                    { this.renderAddToCart() }
                </div>
            </>
        );
    }

    renderOfferLabel() {
        const { getActiveProduct } = this.props;
        try {
            const { price_range: { minimum_price: { discount: { percent_off } } } } = getActiveProduct();
            // eslint-disable-next-line fp/no-let
            let label;
            if (percent_off > 20) {
                label = 'offer';
            }

            if (label === undefined) {
                return null;
            }

            return (
                   <span
                     block="ProductCard-label ProductCard-label-left ProductCard-label-left"
                     elem={ label }
                     aria-label={ __('Save') }
                   >
                    { label }
                   </span>
            );
        } catch (error) {
            return null;
        }
    }

    renderDiscountPercentage() {
        const { getActiveProduct } = this.props;
        try {
            const { price_range: { minimum_price: { discount: { percent_off } } } } = getActiveProduct();
            if (!percent_off) {
                return null;
            }

            return (
                   <span
                     block="ProductCard-label ProductCard-label"
                     elem="right"
                     aria-label={ __('Save') }
                   >
                    { `${percent_off?.toFixed(0)}% OFF` }
                   </span>
            );
        } catch (error) {
            return null;
        }
    }

    renderProductSize() {
        const { getActiveProduct } = this.props;
        const { attributes } = getActiveProduct();
        if (attributes?.quantity) {
            return (
                <span block="ProductCard" elem="ProductSize">{ attributes.quantity.attribute_value }</span>
            );
        }

        return (
            <span block="ProductCard" elem="ProductSize"> </span>
        );
    }

    renderUseOfProduct() {
        const { getActiveProduct } = this.props;
        const { attributes } = getActiveProduct();
        if (attributes?.product_tags) {
            return (
                <span block="ProductCard" elem="UseOf">{ attributes.product_tags.attribute_value }</span>
            );
        }

        return (
            <span block="ProductCard" elem="UseOf"> </span>
        );
    }

    renderRating() {
        const { getActiveProduct } = this.props;
        try {
            const { rating_summary, review_count } = getActiveProduct();
            // eslint-disable-next-line fp/no-let
            let review;
            if (!rating_summary) {
                return (
                    <div className="ProductCard-Rating" />
                );
            }
            if (review_count < 3) {
                return (
                    <div className="ProductCard-Rating" />
                );
            }
            if (review_count > 999) {
                review = `${review_count / 1000}k`;
            } else {
                review = review_count;
            }

            return (
                <div className="ProductCard-Rating">
                    <div block="ProductCard-Rating" elem="card">
                        <span>{ rating_summary / 20 }</span>
                        <svg width="12px" height="12px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path style={ { fill: '#F09000' } } d="M10 1.3l2.388 6.722H18.8l-5.232 3.948 1.871 6.928L10 14.744l-5.438 4.154 1.87-6.928-5.233-3.948h6.412L10 1.3z" /></svg>
                    </div>
                    <span block="ProductCard-Rating" elem="review">{ review > 1 ? `${review} Reviews` : `${review} Review` }</span>
                </div>
            );
        } catch (error) {
            return null;
        }
    }

    renderFooter() {
        return (
            <div className="ProductCard-Footer">
                { this.renderAddToCart() }
            </div>
        );
    }

    renderCardContent() {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } } onPointerEnter={ () => this.changeShowImg(true) } onPointerLeave={ () => this.changeShowImg(false) }>
                    { this.renderOfferLabel() }
                    { this.renderDiscountPercentage() }
                    { this.renderPicture() }
                    <div block="ProductCard" elem="Content">
                        { /* this.renderReviews() }
                        { this.renderBrand() */ }
                        { this.renderName(false) }
                        { this.renderUseOfProduct() }
                        { /* { this.renderProductSize() } */ }
                        { /* { this.renderRating() } */ }
                        { this.renderPrice() }
                    </div>
                    { /* <div block="ProductCard" elem="VisibleOnHover">
                        { this.renderVisibleOnHover() }
                    </div> */ }
                </div>
            ))
        );
    }

    renderCardListContent() {
        const {
            children, layout, renderContent, product: { name }
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper((
            <div block="ProductCard" elem="Link">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        { this.renderPicture() }
                    </figure>
                </div>
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                    <div block="ProductCard" elem="MainInfo">
                        { this.renderReviews() }
                        { this.renderBrand() }
                        { this.renderMainDetails() }
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        { this.renderPrice() }
                        { this.renderConfigurableOptions() }
                    </div>
                    <div block="ProductCard" elem="ActionWrapper" mods={ { loaded: !!name } }>
                        { this.renderAddToCart() }
                        { this.renderProductActions() }
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>
                </div>
            </div>
        ));
    }

    render() {
        const {
            // children,
            mix,
            isLoading,
            layout
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                { this.renderVisibleOnHover() }
            </li>
        );
    }
}

export default ProductCardComponent;
