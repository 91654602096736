import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

export const SocialLoginDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/SocialLogin/SocialLogin.dispatcher'
);

/** @namespace SocialLogin/Component/LoadConfig/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace SocialLogin/Component/LoadConfig/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    fetchConfig: () => SocialLoginDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch)
    )
});

/** @namespace SocialLogin/Component/LoadConfig/Container */
export class LoadConfigContainer extends PureComponent {
    static propTypes = {
        fetchConfig: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.fetchConfig();
    }

    fetchConfig() {
        const { fetchConfig } = this.props;
        fetchConfig();
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadConfigContainer);
