/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Expandable from './Expandable.component';

/** @namespace PageBuilder/Component/Expandable/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace PageBuilder/Component/Expandable/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace PageBuilder/Component/Expandable/Container */
export class ExpandableContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        isReady: false,
        expandables: null
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    componentDidMount() {
        const { elements } = this.props;
        const { props } = elements;
        const expandableContent = props.children
            .filter((child) => child.name === 'div')
            .reduce((all, curr) => {
                if (!curr) {
                    return all;
                }

                return [...all, {
                    heading: curr.attribs['data-heading'],
                    isOpen: curr.attribs['data-isexpanded'] && curr.attribs['data-isexpanded'] === '1',
                    content: curr.children
                }];
            }, []);

        this.setState({
            isReady: true,
            expandables: expandableContent
        });
    }

    containerProps() {
        const { isReady, expandables } = this.state;

        return {
            isReady,
            expandables
        };
    }

    render() {
        return (
            <Expandable
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableContainer);
