/* eslint-disable react/prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-redundant-should-component-update */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import CompairProductsTable from './CompairProductsTable.component';

/** @namespace Scandi/Component/CompairProductsTable/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.ProductListReducer.isLoading,
    pages: state.ProductListReducer.pages,
    categoryName: state.CategoryReducer.category.name
});

/** @namespace Scandi/Component/CompairProductsTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Scandi/Component/CompairProductsTable/Container */
export class CompairProductsTableContainer extends DataContainer {
    static propTypes = {
        pages: PropTypes.arrayOf(PropTypes.object).isRequired,
        isLoading: PropTypes.bool.isRequired,
        categoryName: PropTypes.string.isRequired
    };

    componentDidMount() {
        this.fetchProducts();
    }

    componentDidUpdate(prev) {
        const { loaded } = this.state;
        if (!loaded) {
            this.fetchProducts();
        }

        // eslint-disable-next-line react/prop-types
        if (prev.categoryName !== this.props.categoryName) {
            this.fetchProducts();
        }
    }

    __construct() {
        this.state = {
            tableProducts: [],
            loaded: false
        };
        this.fetchProducts = this.fetchProducts.bind(this);
    }

    fetchProducts() {
        // eslint-disable-next-line react/prop-types
        const { pages, isLoading } = this.props;

        if (!isLoading) {
            const products = Object.values(pages).reduce((acc, page) => acc.concat(page), []);
            if (products.length < 5) {
                return;
            }
            this.setState({
                tableProducts: products.slice(0, 10),
                loaded: true
            });
        }
    }

    containerProps() {
        const { tableProducts } = this.state;
        const { categoryName } = this.props;
        return { products: tableProducts, name: categoryName };
    }

    render() {
        const { loaded } = this.state;

        if (!loaded) {
            return null;
        }

        return (
            <CompairProductsTable
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompairProductsTableContainer);
