/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts } from 'Util/Product';
import { fetchGetQuery } from 'Util/Request';

import ProductsSlider from './CustomProductsSlider.component';

/** @namespace Scandi/Component/CustomProductsSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandi/Component/CustomProductsSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandi/Component/CustomProductsSlider/Container */
export class CustomProductsSliderContainer extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        categoryName: PropTypes.string.isRequired,
        categoryId: PropTypes.string,
        sortKey: PropTypes.string,
        sortDirection: PropTypes.string,
        background: PropTypes.string,
        numberOfProductsToDisplay: PropTypes.number,
        showInSlider: PropTypes.number,
        sliderType: PropTypes.string,
        productSkus: PropTypes.string,
        endTime: PropTypes.string,
        autoPlay: PropTypes.string,
        autoPlaySpeed: PropTypes.string
    };

    static defaultProps = {
        background: '',
        numberOfProductsToDisplay: 10,
        showInSlider: 0,
        categoryId: null,
        sliderType: '',
        productSkus: null,
        endTime: null,
        autoPlay: 0,
        autoPlaySpeed: 4000,
        sortKey: 'price',
        sortDirection: 'DESC'
    };

    state = {
        isLoading: true,
        products: {},
        showSection: true
    };

    containerFunctions = {
        onTimerFinish: this.onTimerFinish.bind(this)
    };

    componentDidMount() {
        this.setState({ isLoading: true });
        this.getProducts();
    }

    containerProps() {
        const {
            isMobile,
            categoryId,
            categoryName,
            background,
            numberOfProductsToDisplay,
            showInSlider,
            sliderType,
            autoPlay,
            autoPlaySpeed,
            endTime
        } = this.props;
        const {
            isLoading,
            showSection,
            products
        } = this.state;

        return ({
            isMobile,
            categoryId,
            categoryName,
            background,
            isLoading,
            showSection,
            products,
            numberOfProductsToDisplay,
            showInSlider,
            sliderType,
            autoPlay,
            autoPlaySpeed,
            endTime
        }
        );
    }

    onTimerFinish() {
        this.setState({
            showSection: false
        });
    }

    async getProducts() {
        const {
            categoryId, productSkus, numberOfProductsToDisplay, sortKey, sortDirection
        } = this.props;
        const { isLoading } = this.state;
        try {
            if ((categoryId || productSkus) && isLoading) {
                // eslint-disable-next-line fp/no-let
                let filter;
                if (productSkus?.length > 0) {
                    filter = { productsSkuArray: productSkus };
                }
                if (categoryId > -1) {
                    filter = { categoryIds: categoryId };
                }
                const query = ProductListQuery.getQuery({
                    args: {
                        filter,
                        pageSize: numberOfProductsToDisplay,
                        currentPage: 1,
                        sort: {
                            sortKey,
                            sortDirection
                        }
                    }
                });

                const data = await fetchGetQuery(query);
                const { products: { items = [] } = {} } = data;
                const indexed = getIndexedProducts(items);

                this.setState({
                    isLoading: false,
                    products: indexed
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('hello error, ', error);
        }
    }

    render() {
        const { isLoading, products, showSection } = this.state;

        if ((products.length <= 0 && !isLoading) || !showSection) {
            return null;
        }

        return (
            <ProductsSlider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomProductsSliderContainer);
