import BrowserDatabase from 'Util/BrowserDatabase';
import getStore from 'Util/Store';

import {
    FETCH_USER_PROFILE,
    REGISTRATION_COMPLETE,
    SIGN_IN,
    triggerEvent,
    UTM_DATA
} from '../../Gtm.config';

export const signIn = async (args, callback, instance) => {
    const isSignedIn = await callback.apply(instance, args);
    if (isSignedIn) {
        const [customer] = args;
        const utmData = BrowserDatabase.getItem(UTM_DATA);

        const store = getStore();
        const { getState } = store;
        const reduxState = getState();
        const countryCode = reduxState.ConfigReducer.code;
        // clevertap.profile.push({});
        triggerEvent({
            event: SIGN_IN,
            clevertapData: {
                Site: {
                    Email: customer.email,
                    'MSG-email': true,
                    'MSG-push': true,
                    'MSG-sms': true,
                    'MSG-whatsapp': true,
                    'Country Code': countryCode,
                    'UTM Source': utmData?.utm_source,
                    'UTM Medium': utmData?.utm_medium,
                    'UTM Campaign': utmData?.utm_campaign
                }
            },
            utmData
        });
    }

    return isSignedIn;
};

const createAccount = (args, callback, instance) => {
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    triggerEvent({
        event: REGISTRATION_COMPLETE,
        utm_data: utmData
    });

    return callback.apply(instance, args);
};

export const requestCustomerDataExecutePostThen = (args, callback, instance) => {
    const [{ customer }] = args;

    const clevertapData = {
        Site: {
            Name: `${customer.firstname} ${customer.lastname}`
        }
    };

    triggerEvent({
        event: FETCH_USER_PROFILE,
        clevertapData
    });

    return callback.apply(instance, args);
};

export default {
    'Scandi/Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn,
            createAccount
        }
    },
    'Scandi/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then': {
        function: requestCustomerDataExecutePostThen
    }
};
