/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    DISPLAY_SHIPPING_PRICES_BOTH,
    DISPLAY_SHIPPING_PRICES_EXCL_TAX
} from 'Component/CheckoutDeliveryOption/CheckoutDeliveryOption.config';

export const DISPLAY_CART_TAX_IN_SUBTOTAL = {
    INCL_TAX: 'DISPLAY_CART_TAX_IN_SUBTOTAL_INCL_TAX',
    EXCL_TAX: 'DISPLAY_CART_TAX_IN_SUBTOTAL_EXL_TAX',
    BOTH: 'DISPLAY_CART_TAX_IN_SUBTOTAL_BOTH'
};

export const DISPLAY_CART_TAX_IN_SHIPPING = {
    INCL_TAX: 'DISPLAY_CART_TAX_IN_SHIPPING_INCL_TAX',
    EXCL_TAX: 'DISPLAY_CART_TAX_IN_SHIPPING_EXL_TAX',
    BOTH: 'DISPLAY_CART_TAX_IN_SHIPPING_BOTH'
};

export const DISPLAY_CART_TAX_IN_PRICE = {
    INCL_TAX: 'DISPLAY_CART_TAX_IN_PRICE_INCL_TAX',
    EXCL_TAX: 'DISPLAY_CART_TAX_IN_PRICE_EXL_TAX',
    BOTH: 'DISPLAY_CART_TAX_IN_PRICE_BOTH'
};

/** @namespace Scandi/Util/Cart/getCartSubtotal */
export const getCartSubtotal = (state) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                display_tax_in_subtotal
            } = {}
        } = {},
        CartReducer: {
            cartTotals: {
                prices: {
                    subtotal_excluding_tax: {
                        value: subtotal = 0
                    } = {},
                    subtotal_including_tax: {
                        value: subtotal_incl_tax = 0
                    } = {}
                } = {}
            } = {}
        } = {}
    } = state;

    if (display_tax_in_subtotal === DISPLAY_CART_TAX_IN_SUBTOTAL.EXCL_TAX) {
        return subtotal;
    }

    return subtotal_incl_tax;
};

/** @namespace Scandi/Util/Cart/getCartSubtotalSubPrice */
export const getCartSubtotalSubPrice = (state) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                display_tax_in_subtotal
            } = {}
        } = {},
        CartReducer: {
            cartTotals: {
                prices: {
                    subtotal_excluding_tax: {
                        value: subtotal = 0
                    } = {}
                } = {}
            } = {}
        } = {}
    } = state;

    if (display_tax_in_subtotal === DISPLAY_CART_TAX_IN_SUBTOTAL.BOTH) {
        return subtotal;
    }

    return null;
};

/** @namespace Scandi/Util/Cart/getCartItemPrice */
export const getCartItemPrice = (state) => (props) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                display_tax_in_price
            } = {}
        } = {}
    } = state;

    const {
        row_total = 0,
        row_total_incl_tax = 0
    } = props;

    if (display_tax_in_price === DISPLAY_CART_TAX_IN_PRICE.EXCL_TAX) {
        return row_total;
    }

    return row_total_incl_tax;
};

/** @namespace Scandi/Util/Cart/getCartItemSubPrice */
export const getCartItemSubPrice = (state) => (props) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                display_tax_in_price
            } = {}
        } = {}
    } = state;

    const {
        row_total = 0
    } = props;

    if (display_tax_in_price === DISPLAY_CART_TAX_IN_PRICE.BOTH) {
        return row_total;
    }

    return null;
};

/** @namespace Scandi/Util/Cart/getCartShippingPrice */
export const getCartShippingPrice = (state) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                display_tax_in_shipping_amount = ''
            } = {}
        } = {},
        CartReducer: {
            cartTotals: {
                shipping_addresses: {
                    selected_shipping_method = {}
                } = []
            } = {}
        } = {}
    } = state;

    const {
        amount: {
            value: shipping_amount = 0
        } = {},
        amount_incl_tax: shipping_incl_tax = 0
    } = selected_shipping_method || {};

    if (display_tax_in_shipping_amount === DISPLAY_CART_TAX_IN_SHIPPING.EXCL_TAX) {
        return shipping_amount;
    }

    return shipping_incl_tax;
};

/** @namespace Scandi/Util/Cart/getCartShippingSubPrice */
export const getCartShippingSubPrice = (state) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                display_tax_in_shipping_amount
            } = {}
        } = {},
        CartReducer: {
            cartTotals: {
                shipping_addresses: {
                    selected_shipping_method = {}
                } = []
            } = {}
        } = {}
    } = state;

    const {
        amount: {
            value: shipping_amount = 0
        } = {}
    } = selected_shipping_method || {};

    if (display_tax_in_shipping_amount === DISPLAY_CART_TAX_IN_SHIPPING.BOTH) {
        return shipping_amount;
    }

    return null;
};

/** @namespace Scandi/Util/Cart/getCartShippingItemPrice */
export const getCartShippingItemPrice = (state) => (props) => {
    const {
        ConfigReducer: {
            priceTaxDisplay: {
                shipping_price_display_type
            } = {}
        } = {}
    } = state;

    const {
        price_incl_tax = 0,
        price_excl_tax = 0
    } = props;

    if (shipping_price_display_type === DISPLAY_SHIPPING_PRICES_EXCL_TAX) {
        return price_excl_tax;
    }

    return price_incl_tax;
};

/** @namespace Scandi/Util/Cart/getCartShippingItemSubPrice */
export const getCartShippingItemSubPrice = (state) => (props) => {
    const {
        ConfigReducer: {
            priceTaxDisplay: {
                shipping_price_display_type
            } = {}
        } = {}
    } = state;

    const {
        price_excl_tax = 0
    } = props;

    if (shipping_price_display_type === DISPLAY_SHIPPING_PRICES_BOTH) {
        return price_excl_tax;
    }

    return null;
};

/** @namespace Scandi/Util/Cart/getCartTotalSubPrice */
export const getCartTotalSubPrice = (state) => {
    const {
        ConfigReducer: {
            cartDisplayConfig: {
                include_tax_in_order_total
            } = {}
        } = {},
        CartReducer: {
            cartTotals: {
                prices: {
                    grand_total: {
                        value: grand_total = 0
                    } = {},
                    applied_taxes = []
                } = {}
            } = {}
        } = {}
    } = state;

    if (include_tax_in_order_total) {
        return applied_taxes.reduce((acc, { amount: { value: tax_amount = 0 } = {} }) => acc - tax_amount, grand_total);
    }

    return null;
};

/** @namespace Scandi/Util/Cart/getItemsCountLabel */
export const getItemsCountLabel = (items_qty) => (items_qty === 1 ? __('1 item') : __('%s items', items_qty || 0));

/** @namespace Scandi/Util/Cart/getAllCartItemsSku */
export const getAllCartItemsSku = (cartItems) => cartItems.reduce((acc, item) => {
    acc.push({ sku: item.sku });

    return acc;
}, []);

/** @namespace Scandi/Util/Cart/trimCrossSellDuplicateItems */
export const trimCrossSellDuplicateItems = (items) => items.filter(
    ({
        sku: itemSku,
        product: { variants: itemVariants, id: itemId }
    }, index, array) => {
        if (!index || !itemVariants.length) {
            return true;
        }

        const {
            sku: duplicateSku,
            product: { id: duplicateId }
        } = array.find(({ product: { id: elementId } }) => elementId === itemId);

        return (duplicateId === itemId && duplicateSku === itemSku);
    }
);

/** @namespace Scandi/Util/Cart/getCartCoupon */
export const getCartCoupon = (state) => {
    const {
        CartReducer: {
            cartTotals: {
                prices: {
                    coupon_code = ''
                } = {}
            } = {}
        } = {}
    } = state;

    return coupon_code;
};
