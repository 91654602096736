import {
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    setAuthorizationToken
} from 'Util/Auth';
import { getCartId, setCartId } from 'Util/Cart';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

export const socialLogin = async (args, callback, instance) => {
    const [token, dispatch] = args;

    setAuthorizationToken(token);

    ProductCompareDispatcher.then(
        ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
    );

    const cartDispatcher = (await CartDispatcher).default;
    const guestCartToken = getCartId();
    // if customer is authorized, `createEmptyCart` mutation returns customer cart token
    const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

    if (guestCartToken && guestCartToken !== customerCartToken) {
        // merge guest cart id and customer cart id using magento capabilities
        await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
    }

    setCartId(customerCartToken);
    cartDispatcher.updateInitialCartData(dispatch);

    WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    );

    await instance.requestCustomerData(dispatch);

    dispatch(updateCustomerSignInStatus(true));
    dispatch(updateIsLoading(false));
    dispatch(hideActiveOverlay());
    dispatch(showNotification('success', __('You are successfully logged in!')));

    return true;
};

export default {
    'Scandi/Store/MyAccount/Dispatcher': {
        'member-function': {
            socialLogin
        }
    }
};
