import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { CategoryTreeType } from 'Type/Category.type';

import PlpFooter from './PlpFooter.component';

/** @namespace Scandi/Component/PlpFooter/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isPLP: state.ConfigReducer.isPLP,
    isHome: state.ConfigReducer.isHome,
    category: state.CategoryReducer?.category
});

/** @namespace Scandi/Component/PlpFooter/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Scandi/Component/PlpFooter/Container */
export class PlpFooterContainer extends Component {
    static propTypes = {
        isPLP: PropTypes.bool.isRequired,
        isHome: PropTypes.bool.isRequired,
        category: CategoryTreeType.isRequired
    };

    state = {
        readMore: false
    };

    containerFunctions = {
        handleReadMore: this.handleReadMore.bind(this)
    };

    containerProps() {
        return {
            ...this.props,
            ...this.state
        };
    }

    handleReadMore() {
        const { readMore } = this.state;
        this.setState({ readMore: !readMore });
    }

    render() {
        return (
            <PlpFooter
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlpFooterContainer);
