/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import DynamicQueriesQuery from 'Query/DynamicQueries.query';
import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts } from 'Util/Product';
import { fetchQuery } from 'Util/Request';

/** @namespace Scandi/Component/AutoCacheCleaner/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
});

/** @namespace Scandi/Component/AutoCacheCleaner/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandi/Component/AutoCacheCleaner/Container */
export class AutoCacheCleanerContainer extends PureComponent {
    componentDidMount() {
        // this.fetchProjectVersion();
    }

    fetchProjectVersion() {
        fetchQuery(DynamicQueriesQuery.getQuery({
            graphql_name: 'manage_project_version'
        }))
            .then(
                /** @namespace Scandi/Component/AutoCacheCleaner/Container/AutoCacheCleanerContainer/fetchProjectVersion/fetchQuery/then */
                ({ getDynamicGraphql }) => {
                    const { graphql_items, success } = getDynamicGraphql;
                    if (success) {
                        const [data] = graphql_items;
                        this.clearCaching(data.config_value);
                    } else {
                        console.log('unable to fetch value.');
                    }
                },
                /** @namespace Scandi/Component/AutoCacheCleaner/Container/AutoCacheCleanerContainer/fetchProjectVersion/fetchQuery/then/catch */
                (error) => {
                    console.log('error :', error);
                }
            );
    }

    clearCaching(projectVersion) {
        const version = localStorage.getItem('version');
        if (version !== projectVersion) {
            if ('caches' in window) {
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                });

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }
            localStorage.clear();
            localStorage.setItem('version', projectVersion);
        }
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCacheCleanerContainer);
