import { applyMakeup } from '../orbo.config';

export const setActiveProduct = (args, callback, instance) => {
    const data = callback.apply(instance, args);
    const { product } = instance.props;
    const [selectedProduct] = args;

    const { attributes: { orbo_sku } = {} } = selectedProduct;
    if (!orbo_sku || !selectedProduct) {
        return data;
    }

    const { attributes: { try_now } = {} } = product;

    if (!try_now || try_now.attribute_value.toString() !== '1') {
        return data;
    }

    applyMakeup(orbo_sku.attribute_value);

    return data;
};

export default {
    'Scandi/Route/ProductPage/Container': {
        'member-function': {
            setActiveProduct
        }
    }
};
