/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slider from 'react-slick';

import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import { ProductType } from 'Type/ProductList.type';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductsSlider.style';

/** @namespace Scandi/Component/ProductsSlider/Component/SampleNextArrow */
export function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
          className={ className }
          style={ { ...style } }
          onClick={ onClick }
        >
            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11L1 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </button>
    );
}

/** @namespace Scandi/Component/ProductsSlider/Component/SamplePrevArrow */
export function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={ className }
        style={ { ...style } }
        onClick={ onClick }
      >
        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L11 11L1 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    );
}

/** @namespace Scandi/Component/ProductsSlider/Component */
export class ProductsSliderComponent extends PureComponent {
    static propTypes = {
        categoryName: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        products: PropTypes.arrayOf(ProductType).isRequired,
        background: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
        numberOfProductsToDisplay: PropTypes.number.isRequired,
        showInSlider: PropTypes.number.isRequired,
        maxWidth: PropTypes.number.isRequired
    };

    selectShowInSlider() {
        // const { showInSlider } = this.props;
        // return showInSlider > 0 && showInSlider < num ? showInSlider : num;
        const windoWidth = window.innerWidth;
        return (windoWidth / 270) + 1;
    }

    renderDesktopSlider() {
        const {
            products = [],
            maxWidth
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let totalSlides;
        if (maxWidth > 0 && maxWidth < window.innerWidth) {
            totalSlides = Math.trunc((maxWidth) / 270) + 1;
        } else {
            totalSlides = Math.trunc((window.innerWidth - window.innerWidth / 20) / 270) + 1;
        }
        // eslint-disable-next-line no-unused-vars
        totalSlides = products.length < totalSlides ? products.length : totalSlides;
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const settings = {
            className: 'slider variable-width',
            slidesToShow: totalSlides - 2,
            slidesToScroll: 1,
            variableWidth: true,
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            // lazyLoad: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        return (
            <div block="HomeProductsSlider" elem="products">
                <Slider
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...settings }
                >
                        { products.map((product) => (
                            <ProductCard key={ product.sku } product={ product } />
                        )) }
                </Slider>
            </div>
        );
    }

    renderMobileSlider() {
        const { products } = this.props;
        const productWidth = ((window.innerWidth - 20) / 1.8);

        return (
            <div block="HomeProductsSlider" elem="products">
                { productWidth > 180 && (<style>{ `.ProductCard { min-width: ${ productWidth }px !important }` }</style>) }
                <ul>
                    { products.map((product) => (
                        <ProductCard key={ product.sku } product={ product } />
                    )) }
                </ul>
            </div>
        );
    }

    render() {
        const {
            isLoading, products = [], categoryName, background, isMobile
        } = this.props;

        if (isLoading) {
            return (
                <div block="HomeProductsSlider">
                    <Loader />
                </div>
            );
        }
        if (!isLoading && !products.length) {
            return null;
        }

        return (
            <section block="HomeProductsSlider" style={ { background } }>
                <div block="HomeProductsSlider" elem="title">
                    <span>{ categoryName }</span>
                </div>
                { !isMobile ? this.renderDesktopSlider() : this.renderMobileSlider() }
            </section>
        );
    }
}

export default ProductsSliderComponent;
