/* eslint-disable max-len */
import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import CheckoutQuery from 'Query/Checkout.query';
import {
    PAYMENT_TOTALS,
    SUCCESS_DATA
} from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId, getCartId } from 'Util/Cart';
import {
    appendTokenToHeaders,
    fetchMutation
} from 'Util/Request';
import { appendWithStoreCode, getQueryParam } from 'Util/Url';

import {
    getPostpayPaymentData,
    setPostpayPaymentData
} from '../util/PaymentTokenPersistence';
import { redirectToUrl } from '../util/Redirect';
import {
    POSTPAY_METHODS,
    POSTPAY_PAY_LATER,
    POSTPAY_PAY_LATER_INSTALLMENTS,
    POSTPAY_PAY_NOW_INSTALLMENTS,
    POSTPAY_PROCESSING_STEP
} from './Checkout.config';

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;

    const { paymentMethod: { code, additional_data } } = paymentInformation;
    const guest_cart_id = getCartId();

    if (!POSTPAY_METHODS.includes(code)) {
        callback.apply(instance, args);
        return;
    }
    try {
        const { totals, resetCart, resetGuestCart } = instance.props;
        const { shippingAddress, billingAddress, email } = instance.state;

        console.log('postpaytotals-1', {
            totals,
            billingAddress,
            shippingAddress
        });

        // return;

        // eslint-disable-next-line no-unreachable
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id: guest_cart_id,
            payment_method: {
                code,
                [code]: additional_data
            }
        }));

        const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
        const { placeOrder: { order: { order_id } } } = orderData;

        await fetchMutation(CheckoutQuery.changeOrderStatusMutation(order_id));
        deleteCartId();
        BrowserDatabase.setItem(order_id, 'POSTPAY_ORDER_ID');

        console.log('postpaytotals-2', {
            order_id
        });

        const items = totals.items.reduce((all, current) => {
            if (!current) {
                return all;
            }

            const unit_price = current.prices?.row_total_including_tax?.value;
            all.push({
                reference: `${current.sku}`,
                name: `${current.product?.name}`,
                sku: `${current.sku}`,
                image_url: `${current.product?.thumbnail?.url}`,
                unit_price,
                qty: current.quantity
            });

            return all;
        }, []);

        console.log('postpaytotals-3', {
            items
        });

        // eslint-disable-next-line no-unreachable
        const total_amount = totals?.prices?.grand_total?.value;
        const shipping_tax = totals?.shipping_addresses?.selected_shipping_method?.amount_incl_tax;

        console.log('postpaytotals-4', {
            total_amount,
            shipping_tax
        });

        const data = {
            quoteId: guest_cart_id,
            postPayData: {
                total_amount,
                tax_amount: 0,
                currency: totals.prices.quote_currency_code,
                shipping: {
                    amount: shipping_tax,
                    name: 'Shipping Address',
                    address: {
                        first_name: shippingAddress.firstname,
                        last_name: shippingAddress.lastname,
                        phone: shippingAddress.telephone,
                        line1: shippingAddress.street[0],
                        line2: shippingAddress.street[1],
                        city: shippingAddress.city,
                        country: shippingAddress.country_id,
                        postal_code: shippingAddress.postcode
                    }
                },
                billing_address: {
                    first_name: billingAddress.firstname,
                    last_name: billingAddress.lastname,
                    phone: billingAddress.telephone,
                    line1: billingAddress.street[0],
                    line2: billingAddress.street[1],
                    city: billingAddress.city,
                    country: billingAddress.country_id,
                    postal_code: billingAddress.postcode
                },
                customer: {
                    email,
                    first_name: billingAddress.firstname,
                    last_name: billingAddress.lastname
                },
                items,
                num_instalments: code === POSTPAY_PAY_LATER ? POSTPAY_PAY_LATER_INSTALLMENTS : POSTPAY_PAY_NOW_INSTALLMENTS
            }
        };

        console.log('postpaytotals-5', {
            data
        });

        const config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: appendTokenToHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        };

        console.log('postpaytotals-6', {
            config
        });

        const response = await fetch(appendWithStoreCode('rest/V1/postpay/order/initiate'), config);

        console.log('postpaytotals-7', {
            response
        });
        const { redirect_url, success, message } = await response.json();

        if (success) {
            const { grand_total, items, quote_currency_code } = BrowserDatabase.getItem(PAYMENT_TOTALS);
            const paymentMethodTitle = __('Paid with Postpay');
            const successData = {
                grand_total,
                items,
                currency: quote_currency_code,
                paymentMethodTitle
            };

            BrowserDatabase.setItem({ successData }, SUCCESS_DATA);
            setPostpayPaymentData(data);
            if (isSignedIn()) {
                resetCart();
            } else {
                resetGuestCart();
            }

            redirectToUrl(redirect_url);
            return;
        }
        throw new Error(message);
    } catch (e) {
        instance._handleError(e);
    }
};

const __construct = (args, callback, instance) => {
    const [props] = args;
    const {
        toggleBreadcrumbs,
        match
    } = props;

    console.log('postpay-__construct - 1', {
        args,
        match,
        props: instance.props,
        state: instance.state
    });

    const { params: { step } } = match;
    if (step !== POSTPAY_PAY_LATER) {
        callback.apply(instance, args);
        return;
    }
    toggleBreadcrumbs(false);

    const { postPayData: { billing_address = {}, customer = {} } = {} } = getPostpayPaymentData();

    const order_id = getQueryParam('order_id', window.location);
    const status = getQueryParam('status', window.location);

    console.log('postpay-__construct - 2', {
        order_id,
        status,
        billing_address,
        customer
    });

    // eslint-disable-next-line no-param-reassign
    instance.state = {
        ...instance.state,
        checkoutStep: POSTPAY_PROCESSING_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email: customer.email,
        billingAddress: {
            ...billing_address,
            telephone: billing_address.phone
        },
        postpay: {
            isLoading: true,
            isPostpayPayment: true,
            isError: false,
            errorMessage: ''
        },
        postpay_status: status,
        postpay_id: order_id
    };
};

const componentDidUpdate = (args, callback, instance) => {
    const {
        match: {
            params: {
                step = ''
            } = { }
        } = { }
    } = instance.props;

    console.log('postpay-componentDidUpdate', {
        args,
        props: instance.props,
        state: instance.state
    });

    if (step === POSTPAY_PAY_LATER) {
        return;
    }

    callback.apply(instance, args);
};

const componentDidMount = (args, callback, instance) => {
    const { postpay: { isPostpayPayment } = {} } = instance.state;

    console.log('postpay-componentDidMount - 1', isPostpayPayment);

    if (!isPostpayPayment) {
        callback.apply(instance, args);
        return;
    }

    const cart = getPostpayPaymentData();

    console.log('postpay-componentDidMount - 2', cart);

    if (!cart) {
        instance.setState({
            postpay: {
                ...instance.state.postpay,
                isLoading: false,
                cart: null,
                isError: true,
                errorMessage: 'Cart not active'
            }
        });

        return;
    }

    const { postpay_id, postpay_status } = instance.state;

    const order_id = BrowserDatabase.getItem('POSTPAY_ORDER_ID');

    const data = {
        order_id,
        postpay_id,
        postpay_status
    };

    const config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json'
        })
    };

    fetch(appendWithStoreCode('rest/V1/postpay/order/place'), config)
        .then((res) => res.json())
        .then((response) => {
            const { increment_id, success, message } = response;
            const { resetCart, resetGuestCart, setNavigationState } = instance.props;
            console.log('postpay-componentDidMount - 3', {
                increment_id,
                success,
                message,
                resetCart,
                resetGuestCart,
                setNavigationState
            });
            if (success) {
                deleteCartId();
                BrowserDatabase.deleteItem(PAYMENT_TOTALS);
                if (isSignedIn()) {
                    resetCart();
                } else {
                    resetGuestCart();
                }

                instance.setState({
                    isLoading: false,
                    paymentTotals: {},
                    orderID: increment_id,
                    postpay: {
                        ...instance.state.postpay,
                        isLoading: false,
                        increment_id,
                        isError: false
                    }
                });

                setNavigationState({
                    name: CART_TAB
                });
            } else {
                instance.setState({
                    postpay: {
                        ...instance.state.postpay,
                        isLoading: false,
                        cart: null,
                        isError: true,
                        errorMessage: message
                    }
                });
            }
        })
        .catch((error) => {
            console.log('postpay-componentDidMount - 4', {
                error
            });
            instance.setState({
                postpay: {
                    ...instance.state.postpay,
                    isLoading: false,
                    cart: null,
                    isError: true,
                    errorMessage: 'Something went wrong'
                }
            });
        });
};

const containerProps = (args, callback, instance) => {
    const { postpay: { isPostpayPayment } = {}, postpay } = instance.state;
    console.log('postpay-containerProps - 1', {
        postpay,
        isPostpayPayment
    });
    if (!isPostpayPayment) {
        return callback.apply(instance, args);
    }

    return {
        ...callback(...args),
        postpay
    };
};

export default {
    'Scandi/Route/Checkout/Container': {
        'member-function': {
            __construct,
            componentDidMount,
            handleRedirectIfNoItemsInCart: componentDidUpdate,
            componentDidUpdate,
            containerProps,
            savePaymentMethodAndPlaceOrder
        }
    }
};
