/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import React, { cloneElement, createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import AdsSlider from 'Component/AdsSlider';
import CustomSlider from 'Component/CustomSlider';
import Html from 'Component/Html';
import { DeviceType } from 'Type/Device.type';

import './HtmlSlider.style';

/** @namespace PageBuilder/Component/HtmlSlider/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace PageBuilder/Component/HtmlSlider/Component/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace PageBuilder/Component/HtmlSlider/Component */
export class HtmlSliderComponent extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired
    };

    sliderRef = createRef();

    renderAdsSlider(paddingInline, sliderProps, childEleBag) {
        return (
            <AdsSlider style={ { '--slide-gap': paddingInline } } mix={ { block: 'HtmlSlider' } } { ...sliderProps } sliderRef={ this.sliderRef }>
                { childEleBag.map((HtmlContent) => {
                    if (!HtmlContent) {
                        return null;
                    }

                    if (typeof HtmlContent !== 'string') {
                        return HtmlContent.map((item) => {
                            if (typeof item === 'string') {
                                return <Html key={ Math.random() } content={ item } />;
                            }

                            return cloneElement(item, {
                                key: Math.random()
                            });
                        });
                    }

                    return <Html key={ Math.random() } content={ HtmlContent } />;
                }) }
            </AdsSlider>
        );
    }

    render() {
        const {
            elements: {
                BaseHtmlCode
            }
        } = this.props;

        const {
            propsBag: [rest] = [],
            childEleBag
        } = BaseHtmlCode;

        const paddingInline = rest['data-slide-gap'] || '0px';

        // eslint-disable-next-line fp/no-let
        let sliderProps = {
            sliderTypeInDesktop: rest['data-slider-type-desktop'],
            sliderTypeInMobile: rest['data-slider-type-mobile'],
            class_name: rest['data-slider-class'] || '',
            dots: !!rest['data-slider-dots'],
            rowsToShow: rest['data-slider-rowstoshow'],
            rowsToShowMobile: rest['data-slider-rowstoshow-mobile'],
            dotsMobile: !!rest['data-slider-dots-mobile'],
            arrows: !!rest['data-slider-arrows'],
            arrowsMobile: !!rest['data-slider-arrows-mobile'],
            slidesToScroll: rest['data-slider-slidestoscroll'] ? rest['data-slider-slidestoscroll'] : 1,
            slidesToScrollMobile: rest['data-slider-slidestoscroll-mobile'] ? rest['data-slider-slidestoscroll-mobile'] : 1,
            slidesToShow: rest['data-slider-slidestoshow'] ? rest['data-slider-slidestoshow'] : 1,
            slidesToShowMobile: rest['data-slider-slidestoshow-mobile'] ? rest['data-slider-slidestoshow-mobile'] : 2,
            variablesidewidth: !!rest['data-slider-variablesidewidth'],
            variablesidewidthMobile: !!rest['data-slider-variablesidewidth-mobile'],
            autoplay: !!rest['data-slider-autoplay'],
            autoplayMobile: !!rest['data-slider-autoplay-mobile'],
            infinite: !!rest['data-slider-infinite'],
            lazyload: !!rest['data-slider-lazyload'],
            variableWidth: !!rest['data-slider-variablewidth'],
            centerPadding: rest['data-slider-image-gap'] ? rest['data-slider-image-gap'] : '10px',
            autoplaySpeed: rest['data-slider-autoplaySpeed'] ? rest['data-slider-autoplaySpeed'] : 2000,
            imagesToShowonDesktop: rest['data-slider-imagestoshowondesktop'] ? rest['data-slider-imagestoshowondesktop'] : 2,
            imagesToShowonMobile: rest['data-slider-imagestoshowonmobile'] ? rest['data-slider-imagestoshowonmobile'] : 1
        };

        try {
            sliderProps = { ...sliderProps, responsive: JSON.parse(rest['data-slider-responsive']) };
        } catch (error) {
            sliderProps = { ...sliderProps, responsive: [] };
        }

        const { sliderType } = this.props;
        if (sliderType === 'AdsSlider') {
            return this.renderAdsSlider(paddingInline, sliderProps, childEleBag);
        }

        return (
            <CustomSlider style={ { '--slide-gap': paddingInline } } mix={ { block: 'HtmlSlider' } } { ...sliderProps } sliderRef={ this.sliderRef }>
                { childEleBag.map((HtmlContent) => {
                    if (!HtmlContent) {
                        return null;
                    }

                    if (typeof HtmlContent !== 'string') {
                        return HtmlContent.map((item) => {
                            if (typeof item === 'string') {
                                return <Html key={ Math.random() } content={ item } />;
                            }

                            return cloneElement(item, {
                                key: Math.random()
                            });
                        });
                    }

                    return <Html key={ Math.random() } content={ HtmlContent } />;
                }) }
            </CustomSlider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HtmlSliderComponent);
