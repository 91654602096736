import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';

import SkinAnalyserQuery from '../query/SkinAnalyser.query';

const componentDidMount = (args, callback, instance) => {
    // make query to backend
    const txnid = BrowserDatabase.getItem('SKIN_ANALYZER_TXN_ID');
    const skus = BrowserDatabase.getItem('SKIN_ANALYZER_SKU');

    if (txnid && skus) {
        fetchMutation(SkinAnalyserQuery.savePurchasedFromRecommendation({
            sku: skus.join(','),
            transaction_id: txnid
        }))
            .then((response) => {
                const { savePurchasedFromRecommendation } = response;
                if (savePurchasedFromRecommendation && savePurchasedFromRecommendation.success) {
                    BrowserDatabase.deleteItem('SKIN_ANALYZER_TXN_ID');
                    BrowserDatabase.deleteItem('SKIN_ANALYZER_SKU');
                }
            });
    }

    callback.apply(instance, args);
};

export default {
    'Scandi/Component/CheckoutSuccess/Component': {
        'member-function': {
            componentDidMount
        }
    }
};
