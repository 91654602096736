/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTimer } from 'react-timer-hook';

/** @namespace Scandi/Component/Timer/Index/Timer */
export const Timer = ({ time, onFinish }) => {
    const [expired, setExpired] = useState(false);

    const expireDate = new Date(time);

    const {
        seconds,
        minutes
    } = useTimer({
        expiryTimestamp: expireDate,
        onExpire: () => {
            setExpired(true);
            onFinish();
        }
    });

    const renderMins = () => (minutes > 9 ? minutes : `0${minutes}`);

    const renderSecs = () => (seconds > 9 ? seconds : `0${seconds}`);

    if (expired) {
        return null;
    }

    return (
        <div>{ `${renderMins()}:${renderSecs()}` }</div>
    );
};

Timer.propTypes = {
    time: PropTypes.string.isRequired,
    onFinish: PropTypes.func
};

Timer.defaultProps = {
    onFinish: () => {}
};

export default Timer;
