/* eslint-disable max-lines */
/* eslint-disable max-len */
import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import CheckoutQuery from 'Query/Checkout.query';
import {
    PAYMENT_TOTALS,
    SUCCESS_DATA
} from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId, getCartId } from 'Util/Cart';
import {
    fetchMutation
} from 'Util/Request';
import { getQueryParam } from 'Util/Url';

import SslcommerzeQuery from '../query/Sslcommerze.query';
import { redirectToUrl } from '../util/Redirect';
import {
    PAY_WITH_SSL,
    SSLCOMMERZE_PROCESSING_STEP
} from './Checkout.config';

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;

    const { paymentMethod: { code, additional_data } } = paymentInformation;
    const guest_cart_id = getCartId();

    if (!PAY_WITH_SSL.includes(code)) {
        callback.apply(instance, args);
        return;
    }
    try {
        console.log('sslcommerz-savePaymentMethodAndPlaceOrder - 1', {
            paymentInformation,
            guest_cart_id,
            props: instance.props,
            state: instance.state
        });
        // eslint-disable-next-line no-unreachable
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id: guest_cart_id,
            payment_method: {
                code,
                [code]: additional_data
            }
        }));

        console.log('sslcommerz-savePaymentMethodAndPlaceOrder - 2', {
            props: instance.props,
            state: instance.state
        });

        const response = await fetchMutation(
            SslcommerzeQuery.getCreateSslcommerzePaymentOrderMutation(
                !isSignedIn() ? { guest_cart_id } : {}
            )
        );

        console.log('sslcommerz-savePaymentMethodAndPlaceOrder - 3', {
            response
        });
        const {
            sslCommerzeOrder: {
                success,
                url,
                order_id,
                message
            }
        } = response || {};

        BrowserDatabase.setItem(response, 'SSLCOMMERZE_RESPONSE');

        if (success) {
            const { grand_total, items, quote_currency_code } = BrowserDatabase.getItem(PAYMENT_TOTALS);
            const paymentMethodTitle = __('Paid with SSLCommerze');
            const successData = {
                grand_total,
                items,
                currency: quote_currency_code,
                paymentMethodTitle,
                order_id
            };

            BrowserDatabase.setItem({ successData }, SUCCESS_DATA);

            if (url && url !== '') {
                redirectToUrl(url);
            }

            return;
        }
        throw new Error(message);
    } catch (e) {
        instance._handleError(e);
    }
};

const __construct = (args, callback, instance) => {
    const [props] = args;
    const {
        toggleBreadcrumbs,
        match
    } = props;

    console.log('sslcommerz-__construct - 1', {
        args,
        match,
        props: instance.props,
        state: instance.state
    });

    const { params: { step } } = match;
    if (step !== PAY_WITH_SSL) {
        callback.apply(instance, args);
        return;
    }
    toggleBreadcrumbs(false);

    const order_id = getQueryParam('order_id', window.location);

    // eslint-disable-next-line no-param-reassign
    instance.state = {
        ...instance.state,
        checkoutStep: SSLCOMMERZE_PROCESSING_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        sslcommerze: {
            isLoading: true,
            isSslcommerzePayment: true,
            isError: false,
            errorMessage: ''
        },
        sslcommerze_id: order_id
    };
};

const componentDidUpdate = (args, callback, instance) => {
    const {
        match: {
            params: {
                step = ''
            } = { }
        } = { }
    } = instance.props;

    console.log('sslcommerz-componentDidUpdate', {
        args,
        props: instance.props,
        state: instance.state
    });

    if (step === PAY_WITH_SSL) {
        return;
    }

    callback.apply(instance, args);
};

const componentDidMount = (args, callback, instance) => {
    const { sslcommerze: { isSslcommerzePayment } = {} } = instance.state;

    console.log('sslcommerz-componentDidMount - 1', isSslcommerzePayment);

    if (!isSslcommerzePayment) {
        callback.apply(instance, args);
        return;
    }

    console.log('sslcommerz-componentDidMount - 2');

    const { sslcommerze_id } = instance.state;

    fetchMutation(
        SslcommerzeQuery.validateReponse(sslcommerze_id)
    )
        .then((response) => {
            const { SslcommerzeValidationResponse: { status, message } } = response;
            // const { increment_id, success, message } = response;
            const { resetCart, resetGuestCart, setNavigationState } = instance.props;
            console.log('sslcommerz-componentDidMount - 3', {
                message,
                resetCart,
                resetGuestCart,
                setNavigationState
            });
            if (status) {
                deleteCartId();
                BrowserDatabase.deleteItem(PAYMENT_TOTALS);
                if (isSignedIn()) {
                    resetCart();
                } else {
                    resetGuestCart();
                }

                instance.setState({
                    isLoading: false,
                    paymentTotals: {},
                    orderID: sslcommerze_id,
                    sslcommerze: {
                        ...instance.state.sslcommerze,
                        isLoading: false,
                        increment_id: sslcommerze_id,
                        isError: false
                    }
                });

                setNavigationState({
                    name: CART_TAB
                });
            } else {
                instance.setState({
                    sslcommerze: {
                        ...instance.state.sslcommerze,
                        isLoading: false,
                        cart: null,
                        isError: true,
                        errorMessage: message
                    }
                });
            }
        })
        .catch((error) => {
            console.log('sslcommerz-componentDidMount - 4', {
                error
            });
            instance.setState({
                sslcommerze: {
                    ...instance.state.sslcommerze,
                    isLoading: false,
                    cart: null,
                    isError: true,
                    errorMessage: 'Something went wrong'
                }
            });
        });
};

const containerProps = (args, callback, instance) => {
    const { sslcommerze: { isSslcommerzePayment } = {}, sslcommerze } = instance.state;
    console.log('sslcommerz-containerProps - 1', {
        sslcommerze,
        isSslcommerzePayment
    });
    if (!isSslcommerzePayment) {
        return callback.apply(instance, args);
    }

    return {
        ...callback(...args),
        sslcommerze
    };
};

export default {
    'Scandi/Route/Checkout/Container': {
        'member-function': {
            __construct,
            componentDidMount,
            handleRedirectIfNoItemsInCart: componentDidUpdate,
            componentDidUpdate,
            containerProps,
            savePaymentMethodAndPlaceOrder
        }
    }
};
