/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

import Html from 'SourceComponent/Html';
/** @namespace PageBuilder/Component/HtmlCode/Component */
export class HtmlCodeComponent extends React.PureComponent {
    render() {
        const {
            BaseHtmlCode
        } = this.props.elements;

        const Content = BaseHtmlCode.childEleBag.reduce((acc, val) => acc.concat(val), []);

        return (
            <BaseHtmlCode.Ele>
                { Content.map((HtmlContent) => (typeof HtmlContent === 'string' ? <Html content={ HtmlContent } /> : React.cloneElement(HtmlContent, HtmlContent.props))) }
            </BaseHtmlCode.Ele>
        );
    }
}

export default HtmlCodeComponent;
