/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { applyMakeup, startMakeup, stopMakeup } from '../../orbo.config';
import { closeTryNow, openTryNow } from '../../store/OrboConfig/OrboConfig.action';
import TryNowComponent from './TryNow.component';

/** @namespace Orbo/Component/TryNow/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOrboOpen: state.OrboReducer.isOpen
});

/** @namespace Orbo/Component/TryNow/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    openOrbo: () => dispatch(openTryNow()),
    closeOrbo: () => dispatch(closeTryNow())
});

/** @namespace Orbo/Component/TryNow/Container */
export class TryNowContainer extends PureComponent {
    static propTypes = {
        productOrVariant: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        closeOrbo: PropTypes.func.isRequired,
        openOrbo: PropTypes.func.isRequired,
        isOrboOpen: PropTypes.bool.isRequired
    };

    state = {
        loading: false
    };

    containerFunctions = {
        openIframe: this.openIframe.bind(this),
        closeIframe: this.closeIframe.bind(this)
    };

    containerProps = () => {
        const { loading } = this.state;
        const { isOrboOpen } = this.props;

        return ({
            isOrboOpen,
            isLoading: loading
        });
    };

    openIframe() {
        const { productOrVariant: product, openOrbo } = this.props;

        this.setState({
            loading: true
        });
        const { attributes: { orbo_sku } = {} } = product;
        console.log('orbo-openIframe', {
            product,
            orbo_sku,
            openOrbo
        });
        if (!orbo_sku) {
            this.setState({
                loading: false
            });

            return;
        }
        startMakeup(() => {
            this.setState({
                loading: false
            });
            applyMakeup(orbo_sku.attribute_value);
            openOrbo();
            console.log('orbo-startMakeup-cb');
        });
    }

    closeIframe() {
        const { closeOrbo } = this.props;
        stopMakeup();
        closeOrbo();
    }

    render() {
        return (
            <TryNowComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TryNowContainer);
