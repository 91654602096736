import PropTypes from 'prop-types';
import React from 'react';

const propTypes = (originalMember) => ({
    ...originalMember,
    isSkinAnalyserProduct: PropTypes.bool
});

const renderAddToCart = (args, callback, instance) => {
    const content = callback.apply(instance, args);
    const {
        product,
        isSkinAnalyserProduct
    } = instance.props;

    const { type_id } = product;

    if (!type_id || type_id !== 'simple' || !content) {
        return content;
    }

    return React.cloneElement(content, {
        props: {
            ...content.props,
            isSkinAnalyserProduct
        }
    });
};

export default {
    'Scandi/Component/ProductCard/Component': {
        'static-member': {
            propTypes
        },
        'member-function': {
            renderAddToCart
        }
    }
};
