/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Html from 'Component/Html';
import Loader from 'Component/Loader';

import './Expandable.style';

/** @namespace PageBuilder/Component/Expandable/Component/ExpandableComponent */
export class ExpandableComponent extends PureComponent {
    static propTypes = {
        isReady: PropTypes.bool.isRequired,
        expandables: PropTypes.oneOfType([PropTypes.array]).isRequired
    };

    renderExpandable(expandable) {
        return (
            <ExpandableContent
              key={ expandable.heading }
              heading={ expandable.heading }
              mix={ {
                  block: 'ProductConfigurableAttributes',
                  elem: 'Expandable'
              } }
              isContentExpanded={ expandable.isOpen }
              isArrow
            >
                { expandable.content.map((content) => <Html content={ content.data } />) }
            </ExpandableContent>
        );
    }

    render() {
        const { isReady, expandables } = this.props;

        if (!isReady) {
            return <Loader isLoading />;
        }

        if (isReady && !expandables) {
            return null;
        }

        return (
            <div block="Expandable">
                { expandables.map(this.renderExpandable.bind(this)) }
            </div>
        );
    }
}

export default ExpandableComponent;
