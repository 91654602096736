import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request';
import PropTypes from 'prop-types';

import { getCartId } from 'Util/Cart';

import { COUPON_CODE, MIN_AMOUNT } from '../Coupon.config';
import AutoApplyCouponQuery from '../query/AutoApplyCoupon.query';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

const saveAddressInformation = async (args, callback, instance) => {
    const [addressInformation] = args;
    const {
        totals: {
            prices: {
                grand_total: {
                    value: grand_total
                } = {},
                coupon_code
            } = {}
        } = {},
        removeCouponFromCart,
        showInfoNotification
    } = instance.props;
    const { shipping_address: { telephone = '' } = {} } = addressInformation;

    if (coupon_code === null) {
        callback(...args);

        return;
    }

    // if amount is less and coupon is applied then removed coupon and continue
    if (grand_total < MIN_AMOUNT && coupon_code.toLowerCase() === COUPON_CODE) {
        await removeCouponFromCart();

        callback(...args);

        return;
    }

    // check if coupon is already used
    fetchQuery(AutoApplyCouponQuery.getQuery({
        cart_id: getCartId(),
        phonenumber: telephone
    })).then(({ checkCouponInCart }) => {
        if (checkCouponInCart.success) {
            showInfoNotification(checkCouponInCart.message);
            instance.setState({
                isLoading: false
            });
            removeCouponFromCart();

            return;
        }
        localStorage.setItem('USER_TYPE', checkCouponInCart.customer_type);
        callback(...args);
    }, instance._handleError);

    // return callback(...args);
};

const propTypes = (originalMember) => ({
    ...originalMember,
    removeCouponFromCart: PropTypes.func.isRequired
});

const mapDispatchToProps = (args, callback, _instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        removeCouponFromCart: () => CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.removeCouponFromCart(dispatch)
        )
    };
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            saveAddressInformation
        },
        'static-member': {
            propTypes
        }
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
