/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

import LoadConfig from '../component/LoadConfig';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const SocialLogin = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "skinanalyser" */ '../route/SocialLogin'));

export const SWITCH_ITEMS_TYPE = (originalMember) => ([
    ...originalMember,
    {
        component: <Route path={ withStoreRegex('/social-login') } exact render={ (props) => <SocialLogin { ...props } /> } />,
        position: 100
    }
]);

export const AFTER_ITEMS_TYPE = (originalMember) => ([
    ...originalMember,
    {
        component: <LoadConfig />,
        position: 100
    }
]);

export default {
    'Scandi/Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE,
            AFTER_ITEMS_TYPE
        }
    }
};
