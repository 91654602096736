/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { ChildrenType, MixType, RefType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomSlider.style';

/** @namespace Scandi/Component/CustomSlider/Component/SampleNextArrow */
export const SampleNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { className, style, onClick } = props;
    return (
        <button
          className={ className }
          style={ { ...style } }
          onClick={ onClick }
        >
            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11L1 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </button>
    );
};

/** @namespace Scandi/Component/CustomSlider/Component/SamplePrevArrow */
export const SamplePrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { className, style, onClick } = props;
    return (
      <button
        className={ className }
        style={ { ...style } }
        onClick={ onClick }
      >
        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L11 11L1 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    );
};

/** @namespace Scandi/Component/CustomSlider/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandi/Component/CustomSlider/Component/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandi/Component/CustomSlider/Component */
export class CustomSliderComponent extends PureComponent {
    static propTypes = {
        class_name: PropTypes.string,
        children: ChildrenType.isRequired,
        dots: PropTypes.bool,
        arrows: PropTypes.bool,
        variablesidewidth: PropTypes.bool,
        variablesidewidthMobile: PropTypes.bool,
        slidesToScroll: PropTypes.number,
        slidesToScrollMobile: PropTypes.number,
        slidesToShow: PropTypes.number,
        slidesToShowMobile: PropTypes.number,
        rowsToShow: PropTypes.number,
        rowsToShowMobile: PropTypes.number,
        mix: MixType,
        infinite: PropTypes.bool,
        speed: PropTypes.number,
        autoplay: PropTypes.bool,
        autoplayMobile: PropTypes.bool,
        centerMode: PropTypes.bool,
        centerPadding: PropTypes.string,
        device: DeviceType.isRequired,
        sliderRef: RefType,
        pauseOnFocus: PropTypes.bool,
        dotsMobile: PropTypes.bool,
        arrowsMobile: PropTypes.bool,
        autoplaySpeed: PropTypes.number,
        // eslint-disable-next-line react/forbid-prop-types
        responsive: PropTypes.any,
        variableWidth: PropTypes.bool,
        imagesToShowonDesktop: PropTypes.number,
        imagesToShowonMobile: PropTypes.number,
        sliderTypeInDesktop: PropTypes.string,
        sliderTypeInMobile: PropTypes.string
    };

    static defaultProps = {
        class_name: '',
        dots: true,
        dotsMobile: true,
        variablesidewidth: true,
        variablesidewidthMobile: true,
        arrows: true,
        arrowsMobile: true,
        slidesToScroll: 1,
        slidesToScrollMobile: 1,
        slidesToShow: 1,
        slidesToShowMobile: 1,
        rowsToShow: 1,
        rowsToShowMobile: 1,
        responsive: '',
        variableWidth: false,
        mix: {},
        infinite: true,
        speed: 800,
        autoplaySpeed: 1000,
        autoplay: false,
        autoplayMobile: false,
        centerMode: false,
        centerPadding: '50px',
        sliderRef: null,
        pauseOnFocus: true,
        sliderTypeInDesktop: 'slider',
        sliderTypeInMobile: 'slider',
        imagesToShowonDesktop: 1,
        imagesToShowonMobile: 1
    };

    sliderRef = createRef();

    getSliderRef() {
        const { sliderRef } = this.props;

        return sliderRef || this.sliderRef;
    }

    renderSlider() {
        const {
            class_name,
            children,
            dots,
            dotsMobile,
            arrows,
            arrowsMobile,
            slidesToScroll,
            slidesToScrollMobile,
            slidesToShow,
            slidesToShowMobile,
            infinite,
            rowsToShow,
            rowsToShowMobile,
            speed,
            centerMode,
            autoplay,
            autoplayMobile,
            autoplaySpeed,
            responsive,
            variableWidth,
            centerPadding,
            device,
            imagesToShowonDesktop,
            imagesToShowonMobile,
            // eslint-disable-next-line no-unused-vars
            sliderRef,
            pauseOnFocus,
            variablesidewidth,
            variablesidewidthMobile,
            ...rest
        } = this.props;

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const sliderProps = {
            dots: device.isMobile ? dotsMobile : dots,
            arrows: device.isMobile ? arrowsMobile : arrows,
            infinite,
            speed,
            slidesToShow: device.isMobile ? slidesToShowMobile : slidesToShow,
            slidesToScroll: device.isMobile ? slidesToScrollMobile : slidesToScroll,
            centerMode,
            autoplay: device.isMobile ? autoplayMobile : autoplay,
            centerPadding,
            pauseOnFocus,
            autoplaySpeed,
            rows: !device.isMobile ? rowsToShow : rowsToShowMobile,
            slidesPerRow: 1,
            responsive,
            variableWidth,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        return (
            <section block={ `${class_name !== '' ? class_name : '' } CustomSlider` } { ...rest }>
                <Slider { ...sliderProps } ref={ this.getSliderRef() }>
                    { children }
                </Slider>
                { ((!responsive?.length > 0 || window.innerWidth > 1270) && variableWidth && (!device.isMobile ? variablesidewidth : variablesidewidthMobile)) && (<style>{ `.${class_name} > .slick-slider > .slick-list > .slick-track > .slick-slide {width: calc(${window.innerWidth > 1280 ? 1280 : window.innerWidth}px  / ${device.isMobile ? imagesToShowonMobile : imagesToShowonDesktop} - ${device.isMobile ? 20 / imagesToShowonMobile : 20 / imagesToShowonDesktop}px)!important;}` }</style>) }
                { (variableWidth) && (<style>{ `.${class_name} > .slick-slider > .slick-list > .slick-track > .slick-slide {padding-inline: 8px}` }</style>) }
            </section>
        );
    }

    renderList() {
        const {
            class_name,
            children
        } = this.props;

        return (
            <section block={ `${class_name !== '' ? class_name : 'CustomList' }` }>
                <ul>
                { children }
                </ul>
            </section>
        );
    }

    render() {
        const {
            device,
            sliderTypeInDesktop,
            sliderTypeInMobile
        } = this.props;

        if (device.isMobile) {
            if (sliderTypeInMobile === 'list') {
                return this.renderList();
            }

            return this.renderSlider();
        }

        if (sliderTypeInDesktop === 'list') {
            return this.renderList();
        }

        return this.renderSlider();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSliderComponent);
