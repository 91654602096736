import { UPDATE_SOCIAL_LOGIN_PROVIDERS } from './SocialLogin.action';

/** @namespace SocialLogin/Store/SocialLogin/Reducer/getInitialState */
export const getInitialState = () => ({
    providers: []
});

/** @namespace SocialLogin/Store/SocialLogin/Reducer/SocialLoginReducer */
export const SocialLoginReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SOCIAL_LOGIN_PROVIDERS:
        const { payload } = action;

        return {
            ...state,
            providers: payload
        };

    default:
        return state;
    }
};

export default SocialLoginReducer;
