import { Field } from 'Util/Query';

/** @namespace Scandi/Query/DynamicQueries/Query */
export class DynamicQueriesQuery {
    getQuery(options) {
        return new Field('getDynamicGraphql')
            .addArgument('graphql_name', 'String', options.graphql_name)
            .addFieldList([
                'graphql_name',
                'success',
                'message',
                new Field('graphql_items')
                    .addFieldList([
                        'config_key',
                        'config_value'
                    ])
            ]);
    }
}

export default new DynamicQueriesQuery();
