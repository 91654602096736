/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

import Loader from 'Component/Loader';

import { POSTPAY_PAY_LATER, POSTPAY_PROCESSING_STEP } from './Checkout.config';

import './Checkout.style.scss';

const propTypes = (originalMember) => ({
    ...originalMember,
    postpay: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        isPostpayPayment: PropTypes.bool.isRequired,
        isError: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        cart: PropTypes.any,
        increment_id: PropTypes.string,
        errorMessage: PropTypes.string.isRequired
    }).isRequired,
    orderID: PropTypes.string.isRequired
});

function renderPostpayStep() {
    const { postpay: { isLoading, isError, errorMessage } = {} } = this.props;

    console.log('postpay-renderPostpayStep', {
        isLoading,
        isError,
        errorMessage
    });

    if (isLoading) {
        return <Loader isLoading />;
    }

    if (!isError) {
        return this.renderDetailsStep();
    }

    return (
        <div block="Checkout" elem="Content">{ errorMessage }</div>
    );
}

const stepMap = (member, instance) => {
    console.log('postpay-stepMap', {
        member,
        props: instance.props,
        state: instance.state
    });

    return {
        ...member,
        [POSTPAY_PROCESSING_STEP]: {
            title: __('Loading'),
            url: `/${POSTPAY_PAY_LATER}`,
            render: renderPostpayStep.bind(instance),
            areTotalsVisible: false
        }
    };
};

const render = (args, callback, instance) => {
    const { checkoutStep, postpay: { isLoading, isError } = {} } = instance.props;

    console.log('postpay-render', {
        checkoutStep,
        isLoading,
        isError
    });

    if (checkoutStep !== POSTPAY_PROCESSING_STEP) {
        return callback.apply(instance, args);
    }

    if (isLoading) {
        return (
            <div block="Checkout" elem="Header">
                <div block="Checkout" elem="Title">{ __('Loading, please wait') }</div>
            </div>
        );
    }

    return (
        <>
            { instance.renderStep() }
            <style>
                { `
                    .CheckoutSuccess {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        background: #ffffff;
                        max-width: var(--content-wrapper-width);
                        margin-inline: auto;
                        margin-block: 140px 40px;
                        padding: 24px;
                    }
                ` }
            </style>
        </>
    );
};

export default {
    'Scandi/Route/Checkout/Component': {
        'member-property': {
            stepMap
        },
        'static-member': {
            propTypes
        },
        'member-function': {
            render
        }
    }
};
