/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import SocialLogin from '../component/SocialLogin';

/** @namespace SocialLogin/Plugin/MyAccountSignIn/Component/renderSignInButton */
export const renderSignInButton = (args, callback, instance) => {
    const { props, state } = instance;
    return (
        <>
            { callback.apply(instance, args) }
            <SocialLogin
              { ...props }
              { ...state }
            />
        </>
    );
};

export default {
    'Scandi/Component/AccountForm/Component': {
        'member-function': {
            renderSignInButton
        }
    }
};
