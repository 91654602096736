import PropTypes from 'prop-types';

import BrowserDatabase from 'Util/BrowserDatabase';

import {
    triggerEvent,
    UTM_DATA,
    VIEW_CATEGORY
} from '../../Gtm.config';

const render = (args, callback, instance) => {
    const {
        category = {},
        clevertapTriggered,
        triggerGtm,
        country
    } = instance.props;

    if (Object.keys(category).length && !clevertapTriggered) {
        const utmData = BrowserDatabase.getItem(UTM_DATA);
        const clevertapData = {
            'Category ID': category.id,
            'Category Name': category.name,
            'Category URL': category.url,
            'Country Code': country,
            'UTM Source': utmData?.utm_source,
            'UTM Medium': utmData?.utm_medium,
            'UTM Campaign': utmData?.utm_campaign
        };

        triggerGtm();
        triggerEvent({
            event: VIEW_CATEGORY,
            clevertapData
        });
    }

    return callback(...args);
};

const propTypes = (originalMember) => ({
    ...originalMember,
    clevertapTriggered: PropTypes.bool.isRequired,
    triggerGtm: PropTypes.func.isRequired
});

export default {
    'Scandi/Route/CategoryPage/Component': {
        'static-member': {
            propTypes
        },
        'member-function': {
            render
        }
    }
};
