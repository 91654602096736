/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SocialLogin from './SocialLogin.component';

/** @namespace SocialLogin/Component/SocialLogin/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    providers: state.SocialLoginReducer.providers
});

/** @namespace SocialLogin/Component/SocialLogin/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace SocialLogin/Component/SocialLogin/Container */
export class SocialLoginContainer extends PureComponent {
    static propTypes = {
        providers: PropTypes.array.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps() {
        const { providers } = this.props;

        return {
            providers
        };
    }

    render() {
        return (
            <SocialLogin
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginContainer);
