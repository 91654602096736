Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/sslcommerze/src/plugin/Checkout.component.plugin.js'),
require('./../packages/sslcommerze/src/plugin/Checkout.container.plugin.js'),
require('./../packages/productalert/src/plugin/ProductActions.component.plugin.js'),
require('./../packages/ga-tracking/src/plugin/AddToCart.container.plugin.js'),
require('./../packages/ga-tracking/src/plugin/CategoryPage.container.plugin.js'),
require('./../packages/ga-tracking/src/plugin/Checkout.container.plugin.js'),
require('./../packages/ga-tracking/src/plugin/CheckoutSuccess.component.plugin.js'),
require('./../packages/ga-tracking/src/plugin/HomePage.container.plugin.js'),
require('./../packages/ga-tracking/src/plugin/ProductPage.component.plugin.js'),
require('./../packages/utm-tracker/src/plugin/AppComponent.plugin.js'),
require('./../packages/utm-tracker/src/plugin/CheckoutSuccessComponent.plugin.js'),
require('./../packages/skin-analyse/src/plugin/AddToCart.container.plugin.js'),
require('./../packages/skin-analyse/src/plugin/CheckoutSuccess.component.plugin.js'),
require('./../packages/skin-analyse/src/plugin/ProductCard.component.plugin.js'),
require('./../packages/skin-analyse/src/plugin/ProductCard.container.plugin.js'),
require('./../packages/skin-analyse/src/plugin/ProductRecommendationReducer.plugin.js'),
require('./../packages/skin-analyse/src/plugin/RouterComponent.plugin.js'),
require('./../packages/postpay/src/plugin/Checkout.component.plugin.js'),
require('./../packages/postpay/src/plugin/Checkout.container.plugin.js'),
require('./../packages/postpay/src/plugin/Checkout.query.plugin.js'),
require('./../packages/pincodecheck/src/plugin/ProductActions.component.plugin.js'),
require('./../packages/pincodecheck/src/plugin/ShiprocketReducer.plugin.js'),
require('./../packages/orbo/src/plugin/OrboReducer.plugin.js'),
require('./../packages/orbo/src/plugin/ProductActions.component.plugin.js'),
require('./../packages/orbo/src/plugin/ProductActions.container.plugin.js'),
require('./../packages/orbo/src/plugin/ProductPage.container.plugin.js'),
require('./../packages/coupon-auto-apply/src/plugin/Checkout.container.plugin.js'),
require('./../packages/page-builder/src/plugin/CmsPage.container.plugin.js'),
require('./../packages/page-builder/src/plugin/Html.component.plugin.js'),
require('./../packages/gtm/src/plugin/AddToCart.container.plugin.js'),
require('./../packages/gtm/src/plugin/App.component.plugin.js'),
require('./../packages/gtm/src/plugin/CartPage.container.plugin.js'),
require('./../packages/gtm/src/plugin/CategoryPage.component.plugin.js'),
require('./../packages/gtm/src/plugin/CategoryPage.container.plugin.js'),
require('./../packages/gtm/src/plugin/Checkout.component.plugin.js'),
require('./../packages/gtm/src/plugin/Checkout.container.plugin.js'),
require('./../packages/gtm/src/plugin/CheckoutSuccess.component.plugin.js'),
require('./../packages/gtm/src/plugin/MyAccount.dispatcher.plugin.js'),
require('./../packages/gtm/src/plugin/MyAccountCustomerPopup.container.plugin.js'),
require('./../packages/gtm/src/plugin/NewsletterSubscription.container.plugin.js'),
require('./../packages/gtm/src/plugin/ProductPage.container.plugin.js'),
require('./../packages/gtm/src/plugin/SearchOverlay.container.plugin.js'),
require('./../packages/gtm/src/plugin/SkinAnalyser.container.plugin.js'),
require('./../packages/social-login/src/plugin/AccountForm.component.plugin.js'),
require('./../packages/social-login/src/plugin/MyAccount.dispatcher.plugin.js'),
require('./../packages/social-login/src/plugin/RouterComponent.plugin.js'),
require('./../packages/social-login/src/plugin/SocialLoginReducer.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { render } from 'react-dom';

import AppCrawler from 'Component/AppCrawler';

import 'Util/Polyfill';
import 'Style/main';

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';

        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<AppCrawler />, document.getElementById('root'));
