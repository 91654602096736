/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { PROVIDERS } from './SocialLogin.config';

import './SocialLogin.style';

/** @namespace SocialLogin/Component/SocialLogin/Component */
export class SocialLoginComponent extends PureComponent {
    static propTypes = {
        providers: PropTypes.array.isRequired
    };

    render() {
        const { providers } = this.props;

        if (!providers || providers.length === 0) {
            return null;
        }

        return (
            <>
                { providers.map((provider) => (
                    <a href={ provider.url } block="SocialLogin" elem={ provider.provider }>
                    <img src={ PROVIDERS[provider.provider] } alt={ `Login with ${provider.provider}` } />
                    <span>{ `Sign in with ${provider.provider}` }</span>
                    </a>
                )) }
            </>
        );
    }
}

export default SocialLoginComponent;
