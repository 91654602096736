import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getCartCoupon } from 'Util/Cart';

import ApplyCouponButton from './ApplyCouponButton.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandi/Component/ApplyCouponButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    couponCode: getCartCoupon(state)
});

/** @namespace Scandi/Component/ApplyCouponButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    applyCouponToCart: (couponCode) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.applyCouponToCart(dispatch, couponCode)
    ),
    removeCouponFromCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeCouponFromCart(dispatch)
    )
});

/** @namespace Scandi/Component/ApplyCouponButton/Container */
export class ApplyCouponButtonContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.elementType.isRequired,
        'data-coupon-code': PropTypes.string.isRequired,
        couponCode: PropTypes.string,
        applyCouponToCart: PropTypes.func.isRequired,
        removeCouponFromCart: PropTypes.func.isRequired
    };

    static defaultProps = {
        couponCode: ''
    };

    state = {
        coupon: null,
        isLoading: false
    };

    containerFunctions = {
        onClickUseCoupon: this.onClickUseCoupon.bind(this)
    };

    static getDerivedStateFromProps(props) {
        const { 'data-coupon-code': coupon } = props;

        return {
            coupon
        };
    }

    containerProps() {
        const { children } = this.props;
        const { isLoading } = this.state;

        return {
            children,
            isLoading
        };
    }

    async onClickUseCoupon() {
        const { coupon } = this.state;
        const { couponCode, applyCouponToCart, removeCouponFromCart } = this.props;
        if (!coupon || couponCode === coupon) {
            return;
        }

        this.setState({
            isLoading: true
        });

        if (!couponCode || couponCode === '') {
            await applyCouponToCart(coupon);
            this.setState({
                isLoading: false
            });

            return;
        }

        await removeCouponFromCart();
        await applyCouponToCart(coupon);
        this.setState({
            isLoading: false
        });
    }

    render() {
        return (
            <ApplyCouponButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyCouponButtonContainer);
