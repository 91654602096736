const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

export class CategoryPageContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        triggerGtm: this.triggerGtm.bind(instance)
    });

    triggerGtm() {
        this.setState({
            gtmTriggered: true
        });
    }
}

const containerProps = (args, callback, instance) => {
    const { gtmTriggered } = instance.state;

    return {
        ...callback(...args),
        gtmTriggered
    };
};

const { containerFunctions } = new CategoryPageContainer();

export default {
    'Scandi/Route/CategoryPage/Container': {
        'member-property': {
            state,
            containerFunctions
        },
        'member-function': {
            containerProps
        }
    }
};
