/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import TryNow from '../component/TryNow';
import {
    injectOrboScript
} from '../orbo.config';

import './Orbo.style.scss';

const Orbo = ({
    device,
    isOrboOpen,
    closeOrbo,
    product,
    data,
    addtocart,
    price,
    getActiveProduct,
    qtyChanger
}) => {
    const activeProduct = getActiveProduct();

    const title = activeProduct?.name || '';

    const titleArray = title.split('-') || [];

    const selectedVariation = titleArray[titleArray.length - 1];

    return (
        <div block="OrboWrapper" mods={ { isMobile: device.isMobile, isOpen: isOrboOpen } }>
            <div block="OrboWrapper" elem="Video" mods={ { isMobile: device.isMobile, isOpen: isOrboOpen } }>
                <div block="OrboWrapper" elem="Header">
                    <button block="OrboWrapper" elem="CloseButton" onClick={ closeOrbo }>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_726_6876)">
                                <path d="M7.828 10.9999H20V12.9999H7.828L13.192 18.3639L11.778 19.7779L4 11.9999L11.778 4.22192L13.192 5.63592L7.828 10.9999Z" fill="#ffffff" />
                            </g>
                        </svg>
                    </button>
                    <div block="OrboWrapper" elem="ProductTitle">{ product?.name }</div>
                </div>
                <video id="videoStream" playsinline style={ { display: 'none' } } />
                <canvas
                  id="output"
                  height={ device.isMobile ? window.innerHeight : '540' }
                  width={ device.isMobile ? window.innerWidth : '480' }
                />
                <canvas id="wasm_canvas" style={ { display: 'none' } } />
                <div block="OrboWrapper" elem="Actions">
                    <div block="OrboWrapper" elem="Options">
                        { data }
                    </div>
                    <div block="OrboWrapper" elem="Details">
                        <div block="OrboWrapper" elem="DetailsTop">
                            <div block="OrboWrapper" elem="SelectedVariation">{ selectedVariation }</div>
                            <div block="OrboWrapper" elem="Price">{ price }</div>
                        </div>
                        <div block="OrboWrapper" elem="DetailsBottom">
                            <div block="OrboWrapper" elem="QtyChanger">{ qtyChanger }</div>
                            <div block="OrboWrapper" elem="AddToCart">
                                { addtocart }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Orbo.propTypes = {
    isOrboOpen: PropTypes.bool.isRequired,
    closeOrbo: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    data: PropTypes.object,
    addtocart: PropTypes.object.isRequired,
    qtyChanger: PropTypes.object.isRequired,
    price: PropTypes.object.isRequired,
    getActiveProduct: PropTypes.func.isRequired
};

Orbo.defaultProps = {
    data: null
};

const renderConfigurableOptions = (args, callback, instance) => {
    const {
        device,
        isOrboOpen,
        closeOrbo,
        product,
        getActiveProduct
    } = instance.props;

    const data = callback.apply(instance, args);
    const addtocart = instance.renderAddToCartActionBlock();
    const qtyChanger = instance.renderQuantityChanger();
    const price = instance.renderPriceWithSchema();

    return (
        <>
            { data }
            { ReactDOM.createPortal(
                <Orbo
                  device={ device }
                  isOrboOpen={ isOrboOpen }
                  closeOrbo={ closeOrbo }
                  product={ product }
                  data={ data }
                  addtocart={ addtocart }
                  qtyChanger={ qtyChanger }
                  price={ price }
                  getActiveProduct={ getActiveProduct }
                />,
                document.body
            ) }
        </>
    );
};

const renderAddToCartButton = (args, callback, instance) => {
    const { product: { attributes: { try_now } = {} } = {}, product, getActiveProduct } = instance.props;

    if (!try_now || try_now.attribute_value.toString() !== '1') {
        return callback.apply(instance, args);
    }
    const productOrVariant = getActiveProduct();
    const { type_id } = productOrVariant;
    // eslint-disable-next-line fp/no-let
    let p = productOrVariant;
    if (type_id === 'configurable') {
        [p] = productOrVariant.variants;
    }

    return (
        <>
            { callback.apply(instance, args) }
            <div className="d-flex TryNow-Wrapper">
                <TryNow product={ product } productOrVariant={ p } />
            </div>
        </>
    );
};

const render = (args, callback, instance) => {
    const { product = {} } = instance.props;

    const { attributes: { try_now } = {} } = product;
    if (!try_now || try_now.attribute_value.toString() !== '1') {
        return callback.apply(instance, args);
    }
    injectOrboScript();
    return callback.apply(instance, args);
};

export default {
    'Scandi/Component/ProductActions/Component': {
        'member-function': {
            renderConfigurableOptions,
            renderAddToCartButton,
            // renderSkuAndStock,
            render
        }
    }
};
