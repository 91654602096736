/* eslint-disable no-nested-ternary */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import './TryNow.style';

/** @namespace Orbo/Component/TryNow/Component */
export class TryNowComponent extends PureComponent {
    static propTypes = {
        isOrboOpen: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        openIframe: PropTypes.func.isRequired,
        closeIframe: PropTypes.func.isRequired
    };

    render() {
        const {
            isOrboOpen,
            openIframe,
            closeIframe,
            isLoading
        } = this.props;

        return (
            <div block="TryNow">
                { isLoading ? <Loader />
                    : isOrboOpen ? (
                        <button
                          type="button"
                          block="Orbo"
                          elem="Button"
                          mix={ { block: 'Button' } }
                          onClick={ closeIframe }
                        >
                            Close
                        </button>
                    ) : (
                        <button
                          type="button"
                          block="Orbo"
                          elem="Button"
                          onClick={ openIframe }
                        >
                            <svg fill="currentColor" width="24px" height="24px" viewBox="0 0 21 22" xmlns="http://www.w3.org/2000/svg">
                                { /* eslint-disable-next-line max-len */ }
                                <path fill="currentColor" d="M10.5001 0C6.35812 0 3.00012 3.358 3.00012 7.5C3.00012 8.01367 3.05213 8.51536 3.15051 9H2.10754C2.0596 8.73257 2.02421 8.46093 2.00207 8.18555C1.97107 7.79805 1.64598 7.5 1.25598 7.5C0.81498 7.5 0.467051 7.87791 0.503051 8.31641C0.900926 13.2028 4.83997 17.0992 9.75012 17.4639V20.5H6.25012C6.15074 20.4986 6.05206 20.517 5.95984 20.554C5.86761 20.5911 5.78367 20.6461 5.71289 20.7159C5.64212 20.7857 5.58592 20.8688 5.54755 20.9605C5.50919 21.0522 5.48944 21.1506 5.48944 21.25C5.48944 21.3494 5.50919 21.4478 5.54755 21.5395C5.58592 21.6312 5.64212 21.7143 5.71289 21.7841C5.78367 21.8539 5.86761 21.9089 5.95984 21.946C6.05206 21.983 6.15074 22.0014 6.25012 22H14.7501C14.8495 22.0014 14.9482 21.983 15.0404 21.946C15.1326 21.9089 15.2166 21.8539 15.2873 21.7841C15.3581 21.7143 15.4143 21.6312 15.4527 21.5395C15.491 21.4478 15.5108 21.3494 15.5108 21.25C15.5108 21.1506 15.491 21.0522 15.4527 20.9605C15.4143 20.8688 15.3581 20.7857 15.2873 20.7159C15.2166 20.6461 15.1326 20.5911 15.0404 20.554C14.9482 20.517 14.8495 20.4986 14.7501 20.5H11.2501V17.4639C16.1603 17.0992 20.0993 13.2028 20.4972 8.31641C20.5327 7.87791 20.1853 7.5 19.7443 7.5C19.3543 7.5 19.0297 7.79805 18.9982 8.18555C18.976 8.46093 18.9406 8.73257 18.8927 9H17.8497C17.9481 8.51536 18.0001 8.01367 18.0001 7.5C18.0001 3.358 14.6421 0 10.5001 0ZM10.5001 1.5C13.8086 1.5 16.5001 4.1915 16.5001 7.5C16.5001 10.8085 13.8086 13.5 10.5001 13.5C7.19162 13.5 4.50012 10.8085 4.50012 7.5C4.50012 4.1915 7.19162 1.5 10.5001 1.5ZM2.52356 10.5H3.62903C4.78691 13.1476 7.42561 15 10.5001 15C13.5746 15 16.2133 13.1476 17.3712 10.5H18.4767C17.2578 13.7106 14.1425 16 10.5001 16C6.85769 16 3.74249 13.7106 2.52356 10.5Z" />
                            </svg>
                            Try On
                        </button>
                    ) }
            </div>
        );
    }
}

export default TryNowComponent;
