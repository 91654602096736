import {
    cloneElement
} from 'react';

import BrowserDatabase from 'Util/BrowserDatabase';

export const SUCCESS_DATA = 'SUCCESS_DATA';

const renderDetailsStep = (args, callback, instance) => {
    const {
        shippingAddress: {
            telephone
        },
        countryCode
    } = instance.props;

    const elem = callback.apply(instance, args);

    const successData = BrowserDatabase.getItem(SUCCESS_DATA);

    const returnData = cloneElement(elem, {
        telephone,
        countryCode,
        successData
    });

    return returnData;
};

export default {
    'Scandi/Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep
        }
    }
};
