/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Input from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './ProductAlert.style';

/** @namespace Productalert/Component/ProductAlert/Component */
export class ProductAlertComponent extends PureComponent {
    static propTypes = {
        onChangeEmail: PropTypes.func.isRequired,
        onInputChange: PropTypes.func.isRequired,
        submitPreorder: PropTypes.func.isRequired,
        onClickSubmit: PropTypes.func.isRequired,
        email: PropTypes.string.isRequired,
        preorder: PropTypes.bool.isRequired,
        openPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        popupOpen: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        phone_number: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
        errorMessage: PropTypes.string.isRequired,
        successMessage: PropTypes.string.isRequired
    };

    renderPreOrderForm() {
        const { openPopup } = this.props;

        return (
            <div block="ProductAlert" elem="Preorder">
                <button onClick={ openPopup } block="Button ProductAlert" elem="PreorderButton">Pre Order this Product</button>
                { this.renderPreOrderPopup() }
            </div>
        );
    }

    renderPreOrderPopup() {
        const {
            preorder,
            popupOpen,
            closePopup,
            name,
            email,
            phone_number,
            onInputChange,
            submitPreorder,
            loading,
            errorMessage
        } = this.props;

        if (!popupOpen || !preorder) {
            return null;
        }

        return (
            <div block="ProductAlert" elem="PreorderPopup">
                <div
                  onClick={ closePopup }
                  block="ProductAlert"
                  elem="PreorderOverlay"
                />
                <div block="ProductAlert" elem="PreorderContent">
                    <button block="ProductAlert" elem="PreorderClose" onClick={ closePopup }>&times;</button>
                    <div block="ProductAlert" elem="PreorderInput">
                        <label htmlFor="name">
                            Name
                            <span className="required">*</span>
                        </label>
                        <input type="text" id="name" name="name" value={ name } onChange={ onInputChange } disabled={ loading } />
                    </div>
                    <div block="ProductAlert" elem="PreorderInput">
                        <label htmlFor="email">
                            Email
                            <span>(Optional)</span>
                        </label>
                        <input type="text" id="email" name="email" value={ email } onChange={ onInputChange } disabled={ loading } />
                    </div>
                    <div block="ProductAlert" elem="PreorderInput">
                        <label htmlFor="phone_number">
                            Mobile Number
                            <span className="required">*</span>
                        </label>
                        <input type="text" id="phone_number" name="phone_number" value={ phone_number } onChange={ onInputChange } disabled={ loading } />
                    </div>
                    <div block="ProductAlert" elem="PreorderSubmit">
                        <button block="Button" onClick={ submitPreorder }>Submit</button>
                        { errorMessage !== '' ? <p block="ProductAlert" elem="PreorderError">{ errorMessage }</p> : '' }
                    </div>
                    <Loader isLoading={ loading } />
                </div>
            </div>
        );
    }

    render() {
        const {
            preorder,
            onChangeEmail,
            onClickSubmit,
            email,
            loading,
            successMessage,
            errorMessage
        } = this.props;

        if (preorder) {
            return this.renderPreOrderForm();
        }

        return (
            <div block="ProductAlert">
                <div block="ProductAlert" elem="Heading">Notify me when the product is available</div>
                <div block="ProductAlert" elem="Form">
                    <Input
                      type={ FIELD_TYPE.email }
                      attr={ {
                          id: 'email',
                          name: 'email',
                          placeholder: __('Your email address'),
                          mix: { block: 'ProductAlert', elem: 'Input' },
                          defaultValue: email
                      } }
                      events={ {
                          onChange: onChangeEmail
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.email
                      } }
                    />
                    <button block="Button" type="button" onClick={ onClickSubmit }>Notify me</button>
                </div>
                { errorMessage !== '' ? <div block="ProductAlert" elem="ErrorMessage">{ errorMessage }</div> : null }
                { successMessage !== '' ? <div block="ProductAlert" elem="SuccessMessage">{ successMessage }</div> : null }
                <Loader isLoading={ loading } />
            </div>
        );
    }
}

export default ProductAlertComponent;
