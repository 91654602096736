import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import './ApplyCouponButton.style';

/** @namespace Scandi/Component/ApplyCouponButton/Component */
export class ApplyCouponButtonComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.elementType.isRequired,
        onClickUseCoupon: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    render() {
        const { children, onClickUseCoupon, isLoading } = this.props;
        return (
            <>
                <button disabled={ isLoading } block="ApplyCouponButton" onClick={ onClickUseCoupon }>
                    { children }
                </button>
                <Loader isLoading={ isLoading } />
            </>
        );
    }
}

export default ApplyCouponButtonComponent;
