/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { ChildrenType, MixType, RefType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AdsSlider.style';

/** @namespace Scandi/Component/AdsSlider/Component/SampleNextArrow */
export const SampleNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { className, style, onClick } = props;
    return (
        <button
          className={ className }
          style={ { ...style } }
          onClick={ onClick }
        >
            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11L1 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </button>
    );
};

/** @namespace Scandi/Component/AdsSlider/Component/SamplePrevArrow */
export const SamplePrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { className, style, onClick } = props;
    return (
      <button
        className={ className }
        style={ { ...style } }
        onClick={ onClick }
      >
        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L11 11L1 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    );
};

/** @namespace Scandi/Component/AdsSlider/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandi/Component/AdsSlider/Component/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandi/Component/AdsSlider/Component */
export class AdsSliderComponent extends PureComponent {
    static propTypes = {
        class_name: PropTypes.string,
        children: ChildrenType.isRequired,
        dots: PropTypes.bool,
        arrows: PropTypes.bool,
        slidesToScroll: PropTypes.number,
        slidesToShow: PropTypes.number,
        imagesToShowonDesktop: PropTypes.number,
        imagesToShowonMobile: PropTypes.number,
        mix: MixType,
        infinite: PropTypes.bool,
        speed: PropTypes.number,
        autoplay: PropTypes.bool,
        centerMode: PropTypes.bool,
        centerPadding: PropTypes.string,
        device: DeviceType.isRequired,
        sliderRef: RefType,
        pauseOnFocus: PropTypes.bool
    };

    static defaultProps = {
        class_name: '',
        dots: true,
        arrows: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        imagesToShowonDesktop: 2,
        imagesToShowonMobile: 1.2,
        mix: {},
        infinite: true,
        speed: 500,
        autoplay: true,
        centerMode: false,
        centerPadding: '0px',
        sliderRef: null,
        pauseOnFocus: true
    };

    sliderRef = createRef();

    getSliderRef() {
        const { sliderRef } = this.props;

        return sliderRef || this.sliderRef;
    }

    renderMobile() {
        const {
            class_name,
            children,
            dots,
            arrows,
            slidesToScroll,
            slidesToShow,
            imagesToShowonMobile,
            infinite,
            speed,
            centerMode,
            autoplay,
            // eslint-disable-next-line no-unused-vars
            sliderRef,
            pauseOnFocus,
            ...rest
        } = this.props;

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const sliderProps = {
            dots,
            arrows,
            infinite,
            speed,
            slidesToShow,
            slidesToScroll,
            centerMode,
            autoplay,
            pauseOnFocus,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            className: 'slider variable-width',
            variableWidth: true
        };

        return (
            <section className={ `banner-slider-mobile-short ${class_name}` } { ...rest }>
                <Slider { ...sliderProps } ref={ this.getSliderRef() }>
                    { children }
                </Slider>
                <style>{ `.banner-slider-mobile-short > .slick-slider > .slick-list > .slick-track > .slick-slide {width: calc(${window.innerWidth}px  / ${imagesToShowonMobile})!important; margin-right: 16px}` }</style>
            </section>
        );
    }

    render() {
        const {
            class_name,
            imagesToShowonDesktop,
            centerPadding,
            children,
            device
        } = this.props;

        if (device.isMobile) {
            return this.renderMobile();
        }

        return (
            <>
            <style>{ `.productImg-Img { width: calc((100% / ${imagesToShowonDesktop}) - (${centerPadding} * ${imagesToShowonDesktop})) }` }</style>
            <div className={ `productImg-Wrap ${class_name}` }>
                { children }
            </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsSliderComponent);
